import React from "react";
import { Modal, Image, Button, Form } from "react-bootstrap";
import { constant } from "../../utils/constant";
import "./index.scss";
import strings from "../../utils/fr.json";
import { phaseLogo } from "../../utils/theme.js";

// Ununsed
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputData: "",
    };
  }

  onChange = (e) => {
    this.setState({ inputData: e.target.value });
  };

  render() {
    const { show, onSubmit } = this.props;
    // var checkMap = process.env.REACT_APP_MAP === constant.pinehill;
    return (
      <Modal
        className="login"
        size="md"
        show={show}
        // onHide={handleLand}
        centered
      >
        <Modal.Header className="justify-content-center">
          <Image src={phaseLogo(constant.phase1)} alt="logo" fluid />
        </Modal.Header>
        <Modal.Body>
          <h2 className="main-title">{`Code d’accès`}</h2>
          <h4 className="sub-title">{strings.Forms.enterCode}</h4>
          <div className="reserved-text">
            <Form.Control
              inputmode="numeric"
              pattern="[0-9]*"
              type="text"
              className={"password-set"}
              value={this.state.inputData}
              placeholder=""
              onChange={(e) => this.onChange(e)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={"light-green"}
            size="lg"
            className="view-more-btn"
            onClick={() => onSubmit(this.state.inputData)}
          >
            {strings.Forms.welcome}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Login;
