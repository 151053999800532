import React from "react";
import { domainePineHill, domaineStCome, greatMap, investirLogo } from "../assets/images";
import { Col, Container, Image, Row } from "react-bootstrap";

export default function Selection() {
  return (
    <div className="great-map-sec h-100" style={{backgroundImage: "url(" + greatMap + ")"}}>
      <Container className="h-100">
        <div className="welcome-sec mx-auto text-center h-100 vstack" style={{maxWidth:"403px"}}>
          <div className="bg-white p-4 welcome-sec-logo">
            <Image src= {investirLogo}/>
            <h6 className="font-bold mb-1">Bienvenue</h6>
            <p>Sélectionnez un de nos 2 domaines</p>
          </div>
          <div className="my-auto py-4">
            <Row>
              <Col xs={6} className="pt-4">
                <a className="d-block" href={process.env.REACT_APP_PINE_HILL_ENDPOINT}>
                  <Image className="w-100 cursor-pointer" src= {domainePineHill}/>
                </a>
              </Col>
              <Col xs={6}>
                <a className="d-block" href={process.env.REACT_APP_STCOME_ENDPOINT}>
                  <Image className="w-100 cursor-pointer" src= {domaineStCome}/>
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
}
