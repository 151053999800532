import React, { Component } from "react";
import "./style.scss";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import {
  domaine_st_come,
  domian_pine_hill_logo,
  loginBannerPineHill,
  loginBannerStCome,
} from "../../assets/images";
import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { ref, onValue } from "firebase/database";
import { rdb, auth, tables } from "../../services/firebase";
import { constant } from "../../utils/constant";
import strings from "../../utils/fr.json";
import { Link } from "react-router-dom";
import { Spinner } from "../index";

class NewLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: true,
      email: "",
      mobilephone: "",
      emailErr: "",
      numberErr: "",
      resetEmailLinkEnable: true,
    };
  }

  componentDidUpdate(preProps) {
    if (preProps.prefillEmail !== this.props.prefillEmail) {
      this.setState({ email: this.props.prefillEmail });
    }
  }

  handleLoginUser = async () => {
    const { email, mobilephone } = this.state;
    const { handleLoader } = this.props;
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.trim() === "" && mobilephone.trim() === "") {
      this.setState({
        emailErr: strings.Errors.emailEmpty,
        numberErr: strings.Errors.mobileEmpty,
      });
    } else if (email.trim() === "") {
      this.setState({
        emailErr: strings.Errors.emailEmpty,
        numberErr: "",
      });
    } else if (!email.match(emailRegex)) {
      this.setState({
        emailErr: strings.Errors.invalidEmail,
        numberErr: "",
      });
    } else if (mobilephone.trim() === "") {
      this.setState({
        numberErr: strings.Errors.mobileEmpty,
      });
    } else {
      handleLoader(true);
      this.login_user({ email, mobilephone });
    }
  };

  login_user = async (obj) => {
    const { handleLoader, handlePrefillEmail } = this.props;
    try {
      await signInWithEmailAndPassword(
        auth,
        obj.email,
        obj.mobilephone //.replace(/[^0-9]/gi, "")
      ).then((userCredential) => {
        const user = userCredential.user;
        const starCountRef = ref(rdb, `${tables.users}${user.uid}`);
        onValue(starCountRef, (snapshot) => {
          const usersData = snapshot.val();
          if (usersData?.type !== "admin") {
            this.props.handleLogin();
            handlePrefillEmail();
            localStorage.setItem("@userId", user.uid);
            localStorage.setItem(
              "@userData",
              JSON.stringify({
                name: usersData?.firstname || "",
                lastname: usersData?.lastname || "",
                email: obj.email,
                phone: usersData?.phone,
                company: usersData?.company,
                address: usersData?.address,
              })
            );
            handleLoader(false);
          } else {
            signOut(auth);
            alert("Credencial no valid");
            handleLoader(false);
          }
        });
      });
    } catch (error) {
      // anthony@groupegibault.com
      handleLoader(false);
      if (error.code === "auth/email-already-in-use") {
        alert(strings.Errors.emailAlreadyInUse);
      }
      if (error.code === "auth/invalid-email") {
        alert(strings.Errors.invalidEmail);
      }
      if (error.code === "auth/user-not-found") {
        alert(strings.Errors.userNotFound);
      }
      if (error.code === "auth/wrong-password") {
        // send reset email link enable
        this.setState({ numberErr: strings.Errors.wrongPassword });
        this.setState({ resetEmailLinkEnable: true });
        // alert(strings.Errors.wrongPassword);
      }
      if (error.code === "auth/too-many-requests") {
        // send reset email link enable
        this.setState({ resetEmailLinkEnable: true });
        alert(strings.Errors.toManyAttempts);
      }
    }
  };

  handleInput = (text, key) => {
    this.setState({ [key]: text, emailErr: "", numberErr: "" });
  };

  handleGuest = () => {
    this.props.handleLogin();
    localStorage.setItem("@guest", true);
  };

  renderError = (error) => {
    if (error) {
      return <span className="error-message">{error}</span>;
    }
  };
  handleReset = async (email) => {
    const actionCodeSettings = {
      url: `http://localhost:3000/?email=${email}`,
      handleCodeInApp: false,
    };
    await sendPasswordResetEmail(auth, email, actionCodeSettings);
  };

  render() {
    const {
      show,
      toggleLogin,
      // handleForgotPassword,
      handleRegisterForm,
      loading,
      handlePrefillEmail,
    } = this.props;
    const { emailErr, email, numberErr, resetEmailLinkEnable } = this.state;
    var checkMap = process.env.REACT_APP_MAP === constant.pinehill;

    return (
      <>
        <Spinner loading={loading} />
        <Modal
          show={show}
          fullscreen={true}
          className="new-login-modal"
          onHide={toggleLogin}
        >
          <Modal.Body className="p-0">
            <Image
              src={checkMap ? loginBannerPineHill : loginBannerStCome}
              style={{
                maxHeight: "25vh",
                objectFit: "cover",
                objectPosition: "center",
              }}
              className="w-100"
              alt="login-bg"
              fluid
            />
            <Container>
              <div className="mx-auto" style={{ maxWidth: "956px" }}>
                <Row>
                  <Col className="py-5" xl={5}>
                    <Image
                      style={{ maxWidth: "132px" }}
                      className="mb-4"
                      src={checkMap ? domian_pine_hill_logo : domaine_st_come}
                      alt="logo"
                      fluid
                    />
                    <h5 className="green" style={{ maxWidth: "200px" }}>
                      {strings.Forms.welcomeToMap}
                    </h5>
                    <hr
                      className="opacity-100"
                      style={{ borderColor: "#ECECEC" }}
                    />
                    <p className="fs-15 lh-19">
                      {strings.Forms.loginParagraphFirst}
                    </p>
                    <p className="fs-15 lh-19" style={{ marginBottom: "33px" }}>
                      {strings.Forms.loginParagraphSecond}
                    </p>
                    <Button
                      variant="green-2"
                      className="fw-600"
                      onClick={() => {
                        this.setState({
                          email: "",
                          mobilephone: "",
                          emailErr: "",
                          numberErr: "",
                        });
                        handleRegisterForm();
                        handlePrefillEmail();
                      }}
                    >
                      {strings.Forms.createMyAccount}
                    </Button>
                  </Col>
                  <Col xl={6} className="ms-auto">
                    <Row className="mx-xl-0">
                      <Card className="new-loging-form border-0 p-0">
                        <Card.Body
                          className="p-lg-5"
                          style={{ paddingBottom: "60px" }}
                        >
                          <Image
                            style={{ maxWidth: "180px" }}
                            src={
                              checkMap ? domian_pine_hill_logo : domaine_st_come
                            }
                            alt="logo"
                            fluid
                          />
                          <hr className="form-divider" />
                          <Form>
                            <Form.Group className="mb-3">
                              <Form.Label>{strings.Forms.email}</Form.Label>
                              <Form.Control
                                type="text"
                                value={email}
                                placeholder={strings.Forms.enterHere}
                                onChange={(e) =>
                                  this.handleInput(e.target.value, "email")
                                }
                                name="email"
                              />
                              {this.renderError(emailErr)}
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>{strings.Forms.password}</Form.Label>
                              <Form.Control
                                type="password"
                                placeholder={strings.Forms.enterHere}
                                onChange={(e) =>
                                  this.handleInput(
                                    e.target.value,
                                    "mobilephone"
                                  )
                                }
                              />
                              {this.renderError(numberErr)}
                            </Form.Group>

                            <p className="forgot-pwd-link">
                              <span
                                className="text-decoration-underline cursor-pointer"
                                onClick={async () => {
                                  const { email } = this.state;
                                  const { handleLoader } = this.props;
                                  handleLoader(true);
                                  await sendPasswordResetEmail(auth, email)
                                    .then((res) => {
                                      handleLoader(false);
                                      alert(
                                        strings.Forms.resetPasswordLinkAlert
                                      );
                                    })
                                    .catch((error) => {
                                      console.log("error", error.code);
                                      handleLoader(false);
                                      if (error.code === "auth/missing-email") {
                                        this.setState({
                                          emailErr: strings.Errors.emailEmpty,
                                        });
                                      }
                                      if (
                                        error.code === "auth/user-not-found"
                                      ) {
                                        alert(strings.Errors.userNotFound);
                                      }
                                    });
                                }}
                              >
                                {strings.Forms.ForgotMyPassword}
                              </span>
                            </p>
                            <Button
                              // type="submit"
                              variant="green-2"
                              className="fw-600"
                              onClick={this.handleLoginUser}
                            >
                              {strings.Forms.login}
                            </Button>
                          </Form>
                        </Card.Body>
                      </Card>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default NewLogin;
