import React from "react";
import { Route, Routes } from "react-router-dom";
import { Map, Selection } from "../pages";
import {
  Phase1AreaMap,
  Phase2AreaMap,
  Phase3AreaMap,
  Phase4AreaMap,
  ResetPassword,
} from "../components";
import { STComePhase1, STComePhase2 } from "../components/maps";
import { constant } from "../utils/constant";

const MainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Map />} exact />
      {/* <Route path="/areas" element={<Areas />} /> */}
      <Route
        path="/phase1"
        element={
          process.env.REACT_APP_MAP === constant.pinehill ? (
            <Phase1AreaMap />
          ) : (
            <STComePhase1 />
          )
        }
      />
      <Route
        path="/phase2"
        element={
          process.env.REACT_APP_MAP === constant.pinehill ? (
            <Phase2AreaMap />
          ) : (
            <STComePhase2 />
          )
        }
      />
      <Route path="/phase3" element={<Phase3AreaMap />} />
      <Route path="/phase4" element={<Phase4AreaMap />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route
        path="/phase1/:id"
        element={
          process.env.REACT_APP_MAP === constant.pinehill ? (
            <Phase1AreaMap />
          ) : (
            <STComePhase1 />
          )
        }
      />
      <Route
        path="/phase2/:id"
        element={
          process.env.REACT_APP_MAP === constant.pinehill ? (
            <Phase2AreaMap />
          ) : (
            <STComePhase2 />
          )
        }
      />
      <Route path="/phase3/:id" element={<Phase3AreaMap />} />
      <Route path="/phase4/:id" element={<Phase4AreaMap />} />
      <Route path="/selection" element={<Selection />} />
    </Routes>
  );
};

export default MainRouter;
