import React from "react";
import {
  Modal,
  Row,
  Col,
  Container,
  Image,
  Button,
  Form,
} from "react-bootstrap";
// import PhoneInput from "react-phone-number-input";
import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { ref, onValue } from "firebase/database";
import { rdb, auth, tables } from "../../services/firebase";
import { domaine_st_come, domian_pine_hill_logo } from "../../assets/images";
import { constant } from "../../utils/constant.js";
import "./style.scss";
import strings from "../../utils/fr.json";
import { Spinner } from "../index";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: true,
      email: "",
      mobilephone: "",
      emailErr: "",
      numberErr: "",
      resetEmailLinkEnable: true,
    };
  }

  componentDidUpdate(preProps) {
    if (preProps.prefillEmail !== this.props.prefillEmail) {
      this.setState({ email: this.props.prefillEmail });
    }
  }

  handleLoginUser = async () => {
    const { email, mobilephone } = this.state;
    const { handleLoader } = this.props;
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.trim() === "" && mobilephone.trim() === "") {
      this.setState({
        emailErr: strings.Errors.emailEmpty,
        numberErr: strings.Errors.mobileEmpty,
      });
    } else if (email.trim() === "") {
      this.setState({
        emailErr: strings.Errors.emailEmpty,
        numberErr: "",
      });
    } else if (!email.match(emailRegex)) {
      this.setState({
        emailErr: strings.Errors.invalidEmail,
        numberErr: "",
      });
    } else {
      handleLoader(true);
      this.login_user({ email, mobilephone });
    }
  };

  login_user = async (obj) => {
    const { handleLoader, handlePrefillEmail } = this.props;
    try {
      await signInWithEmailAndPassword(
        auth,
        obj.email,
        obj.mobilephone //.replace(/[^0-9]/gi, "")
      ).then((userCredential) => {
        const user = userCredential.user;
        const starCountRef = ref(rdb, `${tables.users}${user.uid}`);
        onValue(starCountRef, (snapshot) => {
          const usersData = snapshot.val();
          if (usersData?.type !== "admin") {
            this.props.handleLogin();
            handlePrefillEmail();
            localStorage.setItem("@userId", user.uid);
            localStorage.setItem(
              "@userData",
              JSON.stringify({
                name: usersData?.firstname || "",
                lastname: usersData?.lastname || "",
                email: obj.email,
                phone: usersData?.phone,
                company: usersData?.company,
                address: usersData?.address,
              })
            );
            handleLoader(false);
          } else {
            signOut(auth);
            alert("Credencial no valid");
            handleLoader(false);
          }
        });
      });
    } catch (error) {
      // anthony@groupegibault.com
      handleLoader(false);
      if (error.code === "auth/email-already-in-use") {
        alert(strings.Errors.emailAlreadyInUse);
      }
      if (error.code === "auth/invalid-email") {
        alert(strings.Errors.invalidEmail);
      }
      if (error.code === "auth/user-not-found") {
        alert(strings.Errors.userNotFound);
      }
      if (error.code === "auth/wrong-password") {
        // send reset email link enable
        this.setState({ numberErr: strings.Errors.wrongPassword });
        this.setState({ resetEmailLinkEnable: true });
        // alert(strings.Errors.wrongPassword);
      }
      if (error.code === "auth/too-many-requests") {
        // send reset email link enable
        this.setState({ resetEmailLinkEnable: true });
        alert(strings.Errors.toManyAttempts);
      }
    }
  };

  handleInput = (text, key) => {
    this.setState({ [key]: text, emailErr: "", numberErr: "" });
  };

  handleGuest = () => {
    this.props.handleLogin();
    localStorage.setItem("@guest", true);
  };

  renderError = (error) => {
    if (error) {
      return <span className="error-message">{error}</span>;
    }
  };
  handleReset = async (email) => {
    const actionCodeSettings = {
      url: `http://localhost:3000/?email=${email}`,
      handleCodeInApp: false,
    };
    await sendPasswordResetEmail(auth, email, actionCodeSettings);
  };

  renderResetEmailLink = () => {
    const { email } = this.state;
    const { handleLoader } = this.props;
    return (
      <p className="reset-password">
        <span
          onClick={async () => {
            handleLoader(true);
            await sendPasswordResetEmail(auth, email)
              .then((res) => {
                handleLoader(false);
                alert(strings.Forms.resetPasswordLinkAlert);
              })
              .catch((error) => {
                if (error.code === "auth/missing-email") {
                  handleLoader(false);
                  this.setState({ emailErr: strings.Errors.emailEmpty });
                }
              });
            // this.handleReset(email)
          }}
        >
          {strings.Forms.resetPassword}
        </span>
      </p>
    );
  };

  render() {
    const {
      show,
      toggleLogin,
      // handleForgotPassword,
      handleRegisterForm,
      loading,
      handlePrefillEmail,
    } = this.props;
    const { emailErr, email, numberErr, resetEmailLinkEnable } = this.state;
    var checkMap = process.env.REACT_APP_MAP === constant.pinehill;

    return (
      <>
        <Spinner loading={loading} />

        <Modal
          className="login-form"
          show={show}
          fullscreen={this.state.fullscreen}
          onHide={toggleLogin}
        >
          <Modal.Header>
            <Container>
              <Row>
                <Col xl={4} md={6} sm={8} xs={6} className="mx-auto">
                  <Image
                    src={checkMap ? domian_pine_hill_logo : domaine_st_come}
                    alt="logo"
                    fluid
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <h6 className="form-title">{strings.Forms.login}</h6>
              <Row>
                <Col xl={4} sm={8} xs={10} className="mx-auto">
                  <Form.Group className="form-group">
                    {/* <Form.Label className="green d-sm-block d-none">Last Name</Form.Label> */}
                    <Form.Control
                      type="text"
                      value={email}
                      placeholder={strings.Forms.email}
                      onChange={(e) =>
                        this.handleInput(e.target.value, "email")
                      }
                      name="email"
                    />
                    {this.renderError(emailErr)}
                  </Form.Group>
                  <Form.Group className="form-group">
                    {/* <Form.Label className="green d-sm-block d-none">Phone Number</Form.Label> */}

                    {/* <PhoneInput
                      className="custom-form-control"
                      placeholder={strings.Forms.phoneNumber}
                      // value={this.state.mobilephone}
                      onChange={(text) => {
                        this.handleInput(text, "mobilephone");
                      }}
                      // international={true}
                      class="form-control"
                    /> */}

                    <Form.Control
                      type="tel"
                      placeholder={strings.Forms.passwordPlaceholder}
                      onChange={(e) =>
                        this.handleInput(e.target.value, "mobilephone")
                      }
                    />
                    {this.renderError(numberErr)}
                  </Form.Group>
                  {/* <Button
                  onClick={handleForgotPassword}
                  className="button-first"
                  variant="link"
                >
                  Forgot password
                </Button> */}

                  {resetEmailLinkEnable && this.renderResetEmailLink()}
                  <div className="d-flex justify-content-center flex-md-row flex-column-reverse gap-3">
                    <Button
                      onClick={() => {
                        this.setState({
                          email: "",
                          mobilephone: "",
                          emailErr: "",
                          numberErr: "",
                        });
                        handleRegisterForm();
                        handlePrefillEmail();
                      }}
                      className="button-third"
                      variant="white-green"
                    >
                      {strings.Forms.signup}
                    </Button>
                    <Button
                      onClick={this.handleLoginUser}
                      className="button-second"
                      variant="green"
                    >
                      {strings.Forms.login}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer
          // className="d-flex d-sm-none"
          >
            {/* <Container style={{ margin: "5px auto" }}>
              <Button
                className="guest-link"
                variant="link"
                onClick={this.handleGuest}
              >
                {strings.Forms.continueAsGuest}
              </Button>
            </Container> */}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default LoginForm;
