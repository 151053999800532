import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { constant } from "./constant";

export const formatPrice = (price) => {
  return "CA$" + Number(price).toLocaleString();
};

export const selectPipeline = (phase, map) => {
  if (map === constant.pinehill) {
    if (phase === constant.phase1) {
      return "default";
    } else if (phase === constant.phase2) {
      return "15330482";
    } else if (phase === constant.phase3) {
      return "238510";
    } else if (phase === constant.phase4) {
      return "5051732";
    }
  } else {
    if (phase === constant.phase1) {
      return "264354";
    } else if (phase === constant.phase2) {
      return "5040195";
    }
  }
};

export const loadReservationScript = () => {
  if (process.env.REACT_APP_MAP === "pinehill") {
    const script = document.createElement("script");
    script.src = "pine-hill-reservations.js";
    document.head.appendChild(script);
  } else {
    const script = document.createElement("script");
    script.src = "stcome-reservations.js";
    document.head.appendChild(script);
  }
};

export const setTrackingCode = async (
  setIdentity,
  setPathPageView,
  setTrackPageView
) => {
  const userDataString = await localStorage.getItem("@userData");
  if (userDataString) {
    const userData = JSON.parse(userDataString);
    const userEmail = userData.email;
    setIdentity(userEmail);
    setPathPageView(window.location.pathname);
    setTrackPageView();
  }
};

export const filterString = (inputString) => {
  return inputString?.replace(/[^a-zA-Z0-9-]/g, "");
};
