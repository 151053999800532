import React, { useState, useEffect, Children } from "react";
import { MapInteractionCSS } from "react-map-interaction";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ref, onValue, child, get } from "firebase/database";
import { dealsRef, rdb, tables } from "../../../services/firebase";
import strings from "../../../utils/fr.json";
import {
  PinePhase4AreaMap,
  Header,
  GroundCondition,
  Terrains,
  ViewLand,
  SubmitDetails,
  About,
  Spinner,
} from "../../index";
import { phase4AreaSet } from "../../../utils/data";
import { colors } from "../../../utils/theme";
import {
  selectPipeline,
  loadReservationScript,
  setTrackingCode,
  filterString,
} from "../../../utils/helper";
import { constant } from "../../../utils/constant";
import { areaRule } from "./areaRule";
import { EventDialog } from "./eventDialog";
import { dome, chalet, minichalet } from "../../../assets/images";
import { Location3 } from "../../mapSVGs/pineHill/location";

const Phase4AreaMap = () => {
  var location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { setIdentity, setPathPageView, setTrackPageView } = useTrackingCode();

  const [isLandModal, setIsLandModal] = useState(false);
  const [value, setValue] = useState({
    scale: 0.5, //1.2,
    translation: { x: -200, y: -1000 },
  });
  const [loading, setLoading] = useState(false);
  const [isTerrains, setIsTerrains] = useState(false);
  const [activeArea, setActiveArea] = useState(null);
  const [isSubmitDetails, setIsSubmitDetails] = useState(false);
  const [dragheadercompass, setDragheadercompass] = useState(0);
  const [dealsData, setDealsData] = useState(phase4AreaSet);
  const [hideonDrag, sethideonDrag] = useState("");
  const [aboutModal, setAboutModal] = useState(false);
  const [disponibles, setDisponibles] = useState(0);
  const [isFavourite, setFavourite] = useState(false);
  const [favourite_doc_id, setFavouriteDocId] = useState("");
  const [totalLand, setTotalLand] = useState([]);
  const [planVisitModal, setPlanVisitModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      sethideonDrag("");
    }, 2000);
  });

  useEffect(() => {
    setTrackingCode(setIdentity, setPathPageView, setTrackPageView);
    if (id) {
      setIsTerrains(true);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      onValue(dealsRef, async (snapshot) => {
        const newDealsData = snapshot.val();
        var newData = [];
        var totalLands = [];
        setLoading(false);
        var dealsInfo = await Object.entries(newDealsData)?.filter(
          (item) =>
            item[1]?.properties.pipeline?.value ===
            selectPipeline(constant.phase4, constant.pinehill)
        );

        for (let index = 0; index < phase4AreaSet.length; index++) {
          const element = phase4AreaSet[index];
          var data = [];
          let color = colors.grey;

          for (let i = 0; i < dealsInfo?.length; i++) {
            const deal = dealsInfo[i];
            var newValue =
              filterString(deal[1]?.properties?.identification_du_lot_ph?.value)
                .substr(4, 3)
                .trim() ||
              deal[1]?.properties?.dealname?.value?.substr(4, 3).trim();

            if (
              element.title.toLocaleLowerCase()?.trim() ===
              newValue.toLocaleLowerCase()?.trim()
            ) {
              data = [...data, { ...deal[1], key: deal[0] }];
            }
          }

          const checkStatus =
            data[0]?.properties?.statut_des_promesses_d_achat?.value;

          const reservationStatus =
            data[0]?.properties?.reservation_via_la_carte_interactive?.value;

          const dealStageValue = data[0]?.properties.dealstage?.value;
          const stageValue = constant.dealsStage.pinehill.phase4;

          color = areaRule(
            checkStatus,
            reservationStatus,
            dealStageValue,
            stageValue,
            "phase4"
          );
          // color =
          //   dealsName === "P4-87" ||
          //   dealsName === "P4-84" ||
          //   dealsName === "P4-83" ||
          //   dealsName === "P4-82" ||
          //   dealsName === "P4-81" ||
          //   dealsName === "P4-78"
          //     ? colors.grey
          //     : color;

          var count = checkStatus
            ? checkStatus === strings.Status.complete
              ? 3
              : checkStatus === strings.Status.row2
              ? 2
              : 1
            : 0;
          if (
            activeArea &&
            data.length > 1 &&
            activeArea.deals[0]?.properties?.dealname?.value ===
              data[0]?.properties?.dealname?.value &&
            color === colors.pink &&
            isSubmitDetails
          ) {
            setIsSubmitDetails(false);
            setIsTerrains(false);
            setAboutModal(false);
          }
          newData = [
            ...newData,
            {
              ...element,
              colors: color,
              deals: data,
              count: count,
            },
          ];
          var disponibles = newData.filter(
            (item) =>
              item.colors === colors.green ||
              // item.colors === colors.yellow ||
              item.colors === colors.green3
          );

          totalLands =
            data.length > 0 ? [...totalLands, { colors: color }] : totalLands;

          setDisponibles(disponibles.length);
          setTotalLand(totalLands);
        }
        setDealsData(newData);
        setLoading(false);
      });
    }
    fetchData();
  }, [activeArea, isSubmitDetails]);

  const handleClick = () => {
    sethideonDrag("hide");
  };

  const checkFavourite = async (areas, item) => {
    var userID = await localStorage.getItem("@userId");
    var isGuestLogin = await localStorage.getItem("@guest");
    if (isGuestLogin !== "true") {
      setLoading(true);

      var landFavId = `${userID}${areas?.deals[0]?.dealId}`;
      var wishlistItem = await get(
        child(ref(rdb), `${tables.wishlist}${landFavId}`)
      );
      var isExist = wishlistItem.exists();
      setFavourite(isExist);
      setFavouriteDocId(isExist ? landFavId : 0);
    }
    setActiveArea({ ...item, color: item.colors });
    setIsLandModal(!isLandModal);
    setLoading(false);
  };

  return (
    <>
      <Spinner loading={loading} />
      <div className="phasr4areamap">
        <Header
          isBack={true}
          isCompass={true}
          dragheadercompass={(data) => setDragheadercompass(data)}
          hide={hideonDrag}
          dateLimit={location?.state?.dateLimit}
          timeLimit={location?.state?.timeLimit}
        />
        <div className="map-center">
          <MapInteractionCSS
            value={value}
            onChange={(value) => {
              handleClick();
              setValue(value);
            }}
            minScale={0.4}
          >
            <div
              style={{
                transform: `rotate(${dragheadercompass}deg)`,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <PinePhase4AreaMap />
                <div
                  style={{
                    position: "absolute",
                    top: 2930,
                    left: 1710,
                  }}
                >
                  <Location3 />
                </div>
              </div>
              {/* land area show dealsData array name */}
              {dealsData.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    style={{
                      position: "absolute",
                      top: item.ay,
                      left: item.ax,
                    }}
                    onClick={() => {
                      checkFavourite({ ...item, color: item.colors }, item);
                    }}
                    onTouchEnd={() => {
                      checkFavourite({ ...item, color: item.colors }, item);
                    }}
                  >
                    <item.area
                    // fill={item.color}
                    // fill={item.colors}
                    />
                  </div>
                );
              })}

              {/* land title dealsData array */}
              {dealsData.map((item, index) => {
                if (item.title) {
                  return (
                    <div
                      key={index.toString()}
                      className="phase-4-map-letter"
                      style={{
                        position: "absolute",
                        top: item.y,
                        left: item.x,
                        backgroundColor: item.colors || colors.green3,
                      }}
                      onClick={() => {
                        checkFavourite({ ...item, color: item.colors }, item);
                      }}
                      onTouchEnd={() => {
                        checkFavourite({ ...item, color: item.colors }, item);
                      }}
                    >
                      {item.title}
                      {item.badge !== "" && (
                        <div className="chat-badge">{item.badge}</div>
                      )}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </MapInteractionCSS>
        </div>
      </div>
      {/* <GroundCondition
        hide={hideonDrag}
        disponibles={disponibles}
        totalLand={totalLand.length}
        phase="phase4"
      /> */}
      {isLandModal ? (
        <ViewLand
          show={isLandModal}
          handleTerrain={() => {
            setIsLandModal(!isLandModal);
            setIsTerrains(!isTerrains);
            navigate(`/phase4/${activeArea?.deals[0]?.dealId}`);
          }}
          handleLand={() => setIsLandModal(!isLandModal)}
          activeArea={activeArea}
          phaseMap={{ phase: constant.phase4, map: constant.pinehill }}
          isFavourite={isFavourite}
          favouriteDocId={favourite_doc_id}
          dateLimit={location?.state?.dateLimit}
          timeLimit={location?.state?.timeLimit}
          location={location}
        />
      ) : null}
      {isTerrains ? (
        <Terrains
          show={isTerrains}
          handleTerrains={() => setIsTerrains(!isTerrains)}
          activeArea={activeArea}
          handleSubmitDetails={() => setIsSubmitDetails(!isSubmitDetails)}
          location={location}
          setPlanVisitModal={() => {
            setPlanVisitModal(true);
            loadReservationScript();
          }}
          dateLimit={location?.state?.dateLimit}
          timeLimit={location?.state?.timeLimit}
          mapName="phase-4"
        />
      ) : null}
      <EventDialog
        planVisitModal={planVisitModal}
        setPlanVisitModal={(params) => setPlanVisitModal(params)}
      />
      {/* <div
        style={{ display: planVisitModal ? "block" : "none" }}
        className="event-modal"
        onClick={() => setPlanVisitModal(false)}
      >
        <div className="event-dialog">
          <div className="event-content">
            <div id="eventbrite-widget-container-408235602677"></div>
          </div>
        </div>
      </div> */}
      {isSubmitDetails ? (
        <SubmitDetails
          // show={true}
          activeArea={activeArea}
          show={isSubmitDetails}
          phaseMap={{ phase: constant.phase4, map: constant.pinehill }}
          handleSubmitDetails={() => setIsSubmitDetails(!isSubmitDetails)}
          handleTerrains={() => setIsTerrains(!isTerrains)}
          handleAbout={() => {
            setAboutModal(!aboutModal);
            navigate(-1);
          }}
        />
      ) : null}
      {aboutModal ? (
        <About
          show={aboutModal}
          handleAbout={() => setAboutModal(!aboutModal)}
        />
      ) : null}
    </>
  );
};

export default Phase4AreaMap;
