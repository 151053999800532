import React from "react";
import { domaine_st_come, domian_pine_hill_logo } from "../../assets/images";
import {
  Container,
  Row,
  Col,
  Modal,
  Image,
  Button,
  Form,
  Nav,
  Tab,
} from "react-bootstrap";
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { ref, set } from "firebase/database";
import "./index.scss";
import strings from "../../utils/fr.json";
import { constant } from "../../utils/constant";
import {
  ACC_S_AUX_PORTAILS_ASSOCIATE,
  CREATE_CONTACT,
  CREATE_CUSTOME_OBJECT,
  headersKey,
} from "../../utils/config";
import { rdb, auth, tables } from "../../services/firebase";
import { countries } from "../../utils/countries";
import Autocomplete from "react-google-autocomplete";
import axios from "axios";

const inputs = document.getElementsByClassName("js-fake-input");
const selects = document.getElementsByClassName("select-value");

for (let i = 0; i < inputs.length; i++) {
  getSelected(i);
  selects[i].addEventListener("change", function () {
    getSelected(i);
  });
}

function getSelected(i) {
  inputs[i].value = selects[i].options[selects[i].selectedIndex].text;
}

var formData = {};
var firstTab = 1,
  secondTab = 2,
  thirdTab = 3,
  fourthTab = 4;
class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: firstTab,
      mainHeader: [
        {
          title: strings.Forms.informationsPersonnelles,
          eventKey: firstTab, // "personal-informations",
        },
        {
          title: strings.Forms.contact,
          eventKey: secondTab, //"contact"
        },
        {
          title: strings.Forms.localisation,
          eventKey: thirdTab, // "location"
        },
        {
          title: strings.Forms.preferences,
          eventKey: fourthTab, //"preferences"
        },
      ],
      personalInfo: [
        {
          placeholder: strings.Forms.firstName,
          name: "firstname",
          value: "",
          required: true,
          emptyMsg: strings.Errors.firstNameEmpty,
          hubspot: true,
          isEmptyError: false,
        },
        {
          placeholder: strings.Forms.lastName,
          name: "lastname",
          value: "",
          required: true,
          emptyMsg: strings.Errors.lastNameEmpty,
          hubspot: true,
          isEmptyError: false,
        },
        {
          placeholder: strings.Forms.propertyCompanyName,
          name: "company",
          value: "",
          hubspot: true,
        },
      ],
      contactInfo: [
        {
          placeholder: strings.Forms.email,
          name: "email",
          value: "",
          required: true,
          regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          errorMsg: strings.Errors.invalidEmail,
          emptyMsg: strings.Errors.emailEmpty,
          hubspot: true,
          isEmptyError: false,
          isValidateError: false,
        },
        {
          placeholder: strings.Forms.telephone,
          name: "phone",
          value: "",
          type: "tel",
          required: true,
          regex: /^(\+?\(?\d{3}\)?[\-\s]?)?\d{3}[\-\s]?\d{3,4}$/,
          errorMsg: strings.Errors.invalidMobile,
          emptyMsg: strings.Errors.mobileEmpty,
          hubspot: true,
          isEmptyError: false,
        },
        {
          placeholder: strings.Forms.mobile,
          name: "mobilephone",
          value: "",
          type: "tel",
        },
      ],
      locationInfo: [
        {
          placeholder: strings.Forms.addressPostale,
          name: "address",
          value: "",
          hubspot: true,
        },
        {
          placeholder: strings.Forms.city,
          name: "city",
          value: "",
          required: true,
          emptyMsg: strings.Errors.cityEmptyError,
          hubspot: true,
          isEmptyError: false,
        },
        {
          placeholder: strings.Forms.state,
          name: "state",
          value: "",
          required: true,
          emptyMsg: strings.Errors.stateEmptyError,
          hubspot: true,
          isEmptyError: false,
        },
        {
          placeholder: strings.Forms.country,
          name: "country",
          value: "",
          required: true,
          emptyMsg: strings.Errors.countryEmptyError,
          hubspot: true,
          isEmptyError: false,
        },
        {
          placeholder: strings.Forms.zip,
          name: "zip",
          value: "",
          required: true,
          emptyMsg: strings.Errors.zipEmptyError,
          hubspot: true,
          isEmptyError: false,
        },
      ],
      offers: [
        {
          title: strings.Forms.offers,
          value: "",
          disabled: true,
        }, //quels_offres_vous_interessent_
        { title: strings.Forms.landOnly, value: strings.Forms.landOnly },
        { title: strings.Forms.turnkey, value: strings.Forms.turnkey },
        {
          title: strings.Forms.selfLand,
          value: strings.Forms.selfLand,
        },
        {
          title: strings.Forms.rentalManage,
          value: strings.Forms.rentalManage,
        },
      ],
      offer: "",
      offerError: "",
      isFormProcess: false,
      isEmpty: "",
      isInvalid: "",
      experience: [
        { title: strings.Forms.experience, value: "", disabled: true }, //experience_immobiliere
        { title: strings.Forms.investment, value: strings.Forms.investment },
        { title: strings.Forms.doors, value: strings.Forms.doors },
        { title: strings.Forms.doorsMore, value: strings.Forms.doorsMore },
      ],
      experienceValue: "",
      experienceError: "",
      country: 1,
      states: 0,
      address: "",
      city: "",
      cityEmptyError: "",
      zip: "",
      zipEmptyError: "",
      stateEmptyError: "",
      countryEmptyError: "",
    };
  }

  handleNormalInput = (text, key, errorKey = "") => {
    if (errorKey) {
      this.setState({ [errorKey]: "" });
    }
    this.setState({ [key]: text });
  };

  handleInput = (text, item, index, stateData, stateDataKey) => {
    this.dataChecker(text.target.value, item, index, stateData, stateDataKey);
    let data = stateData;
    data[index].value = text.target.value;
    this.setState({ [stateData]: data });
  };

  dataChecker = (text, item, index, stateData, stateDataKey) => {
    var letters = /^[a-zA-Z\s\d\W_]*$/;
    if (item.name === "phone" && text.match(letters)) {
      if (text) {
        stateData[index].isValidateError = true;
      } else {
        stateData[index].isValidateError = false;
      }
    } else {
      if (item.regex && text.length > 15) {
        if (text && !text.match(item.regex)) {
          stateData[index].isValidateError = true;
        }
      } else {
        stateData[index].isValidateError = false;
      }
    }
    //
    if (item.isEmptyError || item.isValidateError) {
      if (item.regex) {
        stateData[index].isEmptyError = false;
        if (text && text.match(item.regex)) {
          stateData[index].isValidateError = false;
        }
      } else {
        stateData[index].isEmptyError = false;
      }
      this.setState({ [stateDataKey]: stateData });
    }
  };

  renderDropdown = (stateArray, key, valueStore, type = "") => {
    const { offerError, experienceError } = this.state;
    return (
      <Col lg={6}>
        <Form.Group className="form-group">
          <Form.Select
            className="form-control form-select-second"
            style={{ color: this.state[key] === "" ? "#C1C1C1" : "#212529" }}
            value={valueStore}
            name={key}
            onChange={(value) => {
              this.setState({
                [key]: value.target.value,
              });
              if (key === "offer") {
                this.setState({ offerError: "" });
              } else {
                this.setState({ experienceError: "" });
              }
            }}
          >
            {stateArray.map((item, index) => {
              return (
                <option
                  disabled={item.disabled}
                  selected={item.disabled}
                  value={item.value}
                  key={index.toString()}
                >
                  {item.title}
                </option>
              );
            })}
          </Form.Select>
          {experienceError && type === "" && (
            <span className="error-message d-block text-center mt-1">
              {experienceError}
            </span>
          )}
          {offerError && type === "offer" && (
            <span className="error-message d-block text-center mt-1">
              {offerError}
            </span>
          )}
        </Form.Group>
      </Col>
    );
  };

  handleNext = async () => {
    const { handleLoader, handleRegisterForm } = this.props;
    const {
      activeKey,
      personalInfo,
      contactInfo,
      offer,
      experienceValue,
      city,
      zip,
      country,
      states,
      address,
    } = this.state;
    var inputData = activeKey === firstTab ? personalInfo : contactInfo;
    var key = activeKey === firstTab ? "personalInfo" : "contactInfo";

    let isFormDataValid = true;
    var updatedFormData = inputData.map((item, index) => {
      if (item.required) {
        if (item.value) {
          if (item.regex) {
            if (item.value.trim().match(item.regex)) {
              // this.setState({
              formData = { ...formData, ...{ [item.name]: item.value } };
              // });
              return item;
            } else {
              isFormDataValid = false;
              return { ...item, isValidateError: true };
            }
          }
          formData = { ...formData, ...{ [item.name]: item.value } };
          return item;
        } else {
          isFormDataValid = false;
          return { ...item, isEmptyError: true };
        }
      }
      formData = { ...formData, ...{ [item.name]: item.value } };
      return item;
    });
    this.setState({
      [key]: updatedFormData,
    });
    if (activeKey === thirdTab) {
      if (city) {
        formData = { ...formData, city: city };
      } else {
        isFormDataValid = false;
        this.setState({
          cityEmptyError: strings.Errors.cityEmptyError,
        });
      }

      if (zip) {
        formData = { ...formData, zip: zip };
      } else {
        isFormDataValid = false;
        this.setState({
          zipEmptyError: strings.Errors.zipEmptyError,
        });
      }

      if (country !== 0) {
        formData = {
          ...formData,
          country: countries[country].name,
          state: countries[country]?.states[states]?.name || "",
        };
      } else {
        isFormDataValid = false;
        this.setState({
          countryEmptyError: strings.Errors.countryEmptyError,
          stateEmptyError: strings.Errors.stateEmptyError,
        });
      }

      formData = {
        ...formData,
        address: address,
      };
    }
    if (activeKey === fourthTab) {
      if (offer) {
        formData = { ...formData, offer: offer };
      } else {
        isFormDataValid = false;
        this.setState({
          offerError: strings.Errors.offerSelectError,
        });
      }

      if (experienceValue) {
        formData = { ...formData, experience: experienceValue };
      } else {
        isFormDataValid = false;
        this.setState({
          experienceError: strings.Errors.experienceSelectError,
        });
      }
    }

    if (isFormDataValid) {
      if (activeKey === secondTab) {
        fetchSignInMethodsForEmail(auth, formData.email).then((res) => {
          if (res.length > 0) {
            alert(strings.Errors.alreadyRegister);
            this._clearForm();
            handleRegisterForm(formData.email);
          } else {
            this.increaseKey();
          }
        });
      } else {
        this.increaseKey();
      }

      if (this.state.activeKey === fourthTab) {
        handleLoader(true);
        const contactData = await this.createUserInHubspot(formData);
        if (contactData.category !== "OBJECT_ALREADY_EXISTS") {
          let canonicalVid = contactData?.identityProfile?.vid;
          const customObjectResponse = await axios.post(
            CREATE_CUSTOME_OBJECT,
            {
              properties: {
                acc_s_au_portail: "Carte Interactive",
                portail_actif: "Actif",
                mot_de_passe: formData?.phone,
                email_de_connexion: formData?.email,
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
                ...headersKey,
              },
            }
          );

          let customObjectId = customObjectResponse?.data?.id;
          try {
            await axios.put(
              ACC_S_AUX_PORTAILS_ASSOCIATE(customObjectId, canonicalVid),
              {},
              {
                headers: {
                  "Content-Type": "application/json",
                  ...headersKey,
                },
              }
            );
          } catch (error) {
            console.error("error", error);
          }
          handleLoader(true);
          await this.register_users(formData);
        } else {
          handleLoader(true);
          await this.register_users(formData);
        }
      }
    }
  };

  increaseKey = () => {
    this.setState({
      activeKey:
        this.state.activeKey < fourthTab
          ? this.state.activeKey + 1
          : this.state.activeKey,
    });
  };
  register_users = async (obj) => {
    const { handleLoader } = this.props;
    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        obj.email,
        obj.phone.replace(/[^0-9]/gi, "")
      );
      const user = res.user;
      localStorage.setItem("@userId", user.uid);
      localStorage.setItem("@login", true);
      localStorage.setItem(
        "@userData",
        JSON.stringify({
          name: obj.firstname,
          lastname: obj.lastname,
          email: obj.email,
          phone: obj.phone,
          company: obj.company,
          address: obj.address,
        })
      );
      await set(ref(rdb, tables.users + user.uid), {
        uid: user.uid,
        ...obj,
      });
      handleLoader(false);
      this._clearForm();
      this.props.toggleRegisterForm();
      // this.props.handleRegisterForm(formData.email);
    } catch (err) {
      handleLoader(false);
      console.error(err);
      if (err.code === "auth/email-already-in-use") {
        alert(strings.Errors.emailAlreadyInUse);
      }
      if (err.code === "auth/invalid-email") {
        alert(strings.Errors.invalidEmail);
      }
      return "error";
    }
  };

  createUserInHubspot = async (obj) => {
    const { personalInfo, contactInfo } = this.state;
    var checkMap = process.env.REACT_APP_MAP === constant.pinehill;

    var personalInfoProps = personalInfo
      .filter((d) => d.hubspot)
      .map((d) => {
        return { property: d.name, value: d.value };
      });

    var contactInfoProps = contactInfo
      .filter((d) => d.hubspot)
      .map((d) => {
        return { property: d.name, value: d.value };
      });

    var contactData = await fetch(CREATE_CONTACT, {
      method: "post",
      headers: headersKey,
      body: JSON.stringify({
        properties: [
          ...personalInfoProps,
          ...contactInfoProps,
          {
            property: "address",
            value: obj.address,
          },
          {
            property: "city",
            value: obj.city,
          },
          {
            property: "state",
            value: obj.state,
          },
          {
            property: "country",
            value: obj.country,
          },
          {
            property: "zip",
            value: obj.zip,
          },
          {
            property: "origine_du_lead",
            value: checkMap
              ? "Carte interactive" //Carte interactive - PH
              : "Carte interactive - SC",
          },
        ],
      }),
    });
    return contactData.json();
  };

  _clearForm = () => {
    const { personalInfo, contactInfo, locationInfo } = this.state;
    for (let index = 0; index < personalInfo.length; index++) {
      personalInfo[index] = { ...personalInfo[index], value: "" };
    }
    for (let index = 0; index < contactInfo.length; index++) {
      contactInfo[index] = { ...contactInfo[index], value: "" };
    }
    for (let index = 0; index < locationInfo.length; index++) {
      locationInfo[index] = { ...locationInfo[index], value: "" };
    }
    this.setState({
      isFormProcess: false,
      personalInfo: personalInfo,
      contactInfo: contactInfo,
      locationInfo: locationInfo,
      isEmpty: "",
      isInvalid: "",
    });
  };

  renderError = (item, eventKey) => {
    if (this.state.activeKey === eventKey) {
      if (item.isValidateError) {
        return (
          <span className="error-message d-block text-center mt-1">
            {item.errorMsg}
          </span>
        );
      }
      if (item.isEmptyError) {
        return (
          <span className="error-message d-block text-center mt-1">
            {item.emptyMsg}
          </span>
        );
      }
      return null;
    }
    return null;
  };

  compIsType(t, s) {
    for (let z = 0; z < t.length; ++z) if (t[z] === s) return true;
    return false;
  }

  addressSelect = (selected) => {
    let country,
      state,
      city = null;
    for (let i = 0; i < selected.address_components.length; i++) {
      var typ = selected.address_components[i].types;
      if (this.compIsType(typ, "administrative_area_level_1"))
        state = selected.address_components[i].short_name;
      //store the state
      else if (this.compIsType(typ, "locality"))
        city = selected.address_components[i].long_name;
      //store the city
      else if (this.compIsType(typ, "country"))
        country = selected.address_components[i].long_name; //store the country
    }
    if (country) {
      this.setState(
        {
          country: country === "Canada" ? 1 : 2,
          stateEmptyError: "",
          countryEmptyError: "",
        },
        () => {
          if (state) {
            var stateValue = countries[this.state.country].states.findIndex(
              (d) => d.abbreviation === state
            );
            if (stateValue !== -1) {
              this.setState({
                states: stateValue,
                stateEmptyError: "",
              });
            }
          }
        }
      );
    }

    if (city) {
      this.handleNormalInput(city, "city", "cityEmptyError");
    }

    this.handleNormalInput(
      selected.address_components.map((d) => d.long_name).join(", "),
      "address"
    );
  };

  render() {
    const { show, handleRegisterForm } = this.props;
    const {
      mainHeader,
      personalInfo,
      contactInfo,
      offers,
      offer,
      experience,
      experienceValue,
      country,
      address,
      city,
      cityEmptyError,
      zipEmptyError,
      stateEmptyError,
      countryEmptyError,
      zip,
      activeKey,
    } = this.state;
    var checkMap = process.env.REACT_APP_MAP === constant.pinehill;
    return (
      <div>
        <Tab.Container
          id="signup-form-tab"
          defaultActiveKey={this.state.activeKey}
          activeKey={this.state.activeKey}
        >
          <Modal className="signup-form" show={show} fullscreen={true}>
            <Modal.Header className="px-0">
              <Container fluid>
                <div className="py-4">
                  <Row>
                    <Col xs={4} sm={5} lg={4} className="mx-auto text-center">
                      <Image
                        src={checkMap ? domian_pine_hill_logo : domaine_st_come}
                        alt="logo"
                        fluid
                      />
                    </Col>
                  </Row>
                </div>
                <Nav className="signup-form-nav justify-content-md-center py-3">
                  {mainHeader.map((item, index) => {
                    return (
                      <Nav.Item
                        key={index.toString()}
                        onClick={() => {
                          if (index + 1 < activeKey) {
                            this.setState({ activeKey: index + 1 });
                          }
                        }}
                      >
                        <Nav.Link
                          style={{
                            cursor:
                              index + 1 < activeKey ? "pointer" : "default",
                            opacity: index < activeKey ? 1 : 0.3,
                          }}
                          eventKey={item.eventKey}
                        >
                          {index + 1}. {item.title}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
              </Container>
            </Modal.Header>
            <Modal.Body className="pt-lg-5">
              <Container>
                <h6 className="fs-18 orange-2 text-center mb-5">
                  {strings.Forms.register}
                </h6>
                <Tab.Content className="signup-form-tab">
                  <Tab.Pane eventKey={firstTab}>
                    <Row className="gy-4">
                      {personalInfo.map((item, index) => {
                        return (
                          <Col lg={4} key={index.toString()}>
                            <Form.Group className="form-group">
                              <Form.Control
                                type="text"
                                placeholder={item.placeholder}
                                value={item.value}
                                onChange={(txt) =>
                                  this.handleInput(
                                    txt,
                                    item,
                                    index,
                                    personalInfo,
                                    "personalInfo"
                                  )
                                }
                              />
                              {this.renderError(item, firstTab)}
                            </Form.Group>
                          </Col>
                        );
                      })}
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey={secondTab}>
                    <Row className="gy-4">
                      {contactInfo.map((item, index) => {
                        return (
                          <Col lg={4} key={index.toString()}>
                            <Form.Group className="form-group">
                              <Form.Control
                                type="text"
                                placeholder={item.placeholder}
                                value={item.value}
                                onChange={(txt) =>
                                  this.handleInput(
                                    txt,
                                    item,
                                    index,
                                    contactInfo,
                                    "contactInfo"
                                  )
                                }
                              />
                              {this.renderError(item, secondTab)}
                            </Form.Group>
                          </Col>
                        );
                      })}
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey={thirdTab}>
                    <Row className="gy-4">
                      <Col lg={6}>
                        <Form.Group className="form-group">
                          {/* <Form.Control
                            type="text"
                            placeholder={strings.Forms.addressPostale}
                            name="address"
                            value={address}
                            onChange={(txt) =>
                              this.handleNormalInput(txt, "address")
                            }
                          /> */}
                          <Autocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_KEY}
                            onPlaceSelected={(selected) =>
                              this.addressSelect(selected)
                            }
                            options={{
                              componentRestrictions: { country: ["ca", "us"] },
                              fields: [
                                "address_components",
                                "geometry",
                                "icon",
                                "name",
                              ],
                              strictBounds: false,
                              types: ["geocode"],
                            }}
                            className="form-control"
                            placeholder={strings.Forms.addressPostale}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group select-holder">
                          <Form.Select
                            style={{
                              color: country === 0 ? "#C1C1C1" : "#212529",
                            }}
                            onChange={(value, index) => {
                              this.setState({
                                country: Number(value.target.value),
                                stateEmptyError: "",
                                countryEmptyError: "",
                              });
                            }}
                            className="form-control form-select-second select-value"
                          >
                            {countries.map((item, index) => {
                              return (
                                <option
                                  selected={index === country}
                                  value={index}
                                  disabled={index === 0}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <input
                            type="text"
                            className="js-fake-input"
                            value={countries[country]?.name}
                            style={{
                              color: country === 0 ? "#C1C1C1" : "#212529",
                            }}
                          />
                          {countryEmptyError && (
                            <span className="error-message d-block text-center mt-1">
                              {countryEmptyError}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group select-holder">
                          <Form.Select
                            style={{
                              color: country === 0 ? "#C1C1C1" : "#212529",
                            }}
                            onChange={(value, index) => {
                              this.setState({
                                states: value.target.value,
                                stateEmptyError: "",
                              });
                            }}
                            className="form-control form-select-second select-value"
                          >
                            {countries[country].states.map((item, index) => {
                              return (
                                <option
                                  selected={index === this.state.states}
                                  value={index}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <input
                            type="text"
                            className="js-fake-input"
                            value={
                              countries[country]?.states[this.state.states]
                                ?.name || "Province"
                            }
                            style={{
                              color: country === 0 ? "#C1C1C1" : "#212529",
                            }}
                          />
                          {stateEmptyError && (
                            <span className="error-message d-block text-center mt-1">
                              {stateEmptyError}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group">
                          <Form.Control
                            type="text"
                            placeholder={strings.Forms.city}
                            name="city"
                            value={city}
                            onChange={(txt) =>
                              this.handleNormalInput(
                                txt.target.value,
                                "city",
                                "cityEmptyError"
                              )
                            }
                          />
                          {cityEmptyError && (
                            <span className="error-message d-block text-center mt-1">
                              {cityEmptyError}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group">
                          <Form.Control
                            type="text"
                            placeholder={strings.Forms.zip}
                            name="zip"
                            value={zip}
                            onChange={(txt) =>
                              this.handleNormalInput(
                                txt.target.value,
                                "zip",
                                "zipEmptyError"
                              )
                            }
                          />
                          {zipEmptyError && (
                            <span className="error-message d-block text-center mt-1">
                              {zipEmptyError}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                      {/* {locationInfo.map((item, index) => {
                        return (
                          <Col lg={6}>
                            <Form.Group className="form-group">
                              <Form.Control
                                type="text"
                                placeholder={item.placeholder}
                                value={item.value}
                                onChange={(txt) =>
                                  this.handleInput(
                                    txt,
                                    item,
                                    index,
                                    locationInfo,
                                    "locationInfo"
                                  )
                                }
                              />
                              {this.renderError(item, thirdTab)}
                            </Form.Group>
                          </Col>
                        );
                      })} */}
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey={fourthTab}>
                    <Row className="gy-4">
                      {this.renderDropdown(offers, "offer", offer, "offer")}
                      {this.renderDropdown(
                        experience,
                        "experienceValue",
                        experienceValue
                      )}
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
                <div className="d-block border-0">
                  <div className="text-center py-4">
                    <Button
                      variant={"register-following"}
                      onClick={this.handleNext}
                    >
                      {strings.Forms.next}
                    </Button>
                  </div>
                  <p
                    className="grey text-center mx-auto mb-4"
                    style={{ maxWidth: "355px" }}
                    dangerouslySetInnerHTML={{
                      __html: strings.Forms.conditions,
                    }}
                  ></p>
                  <p className="green text-center">
                    {strings.Forms.youTryTo}
                    <a
                      href={() => false}
                      className="green ms-1"
                      onClick={() => {
                        this._clearForm();
                        handleRegisterForm();
                      }}
                    >
                      <b className="cursor-pointer">
                        {strings.Forms.connecter}
                      </b>
                    </a>
                  </p>
                </div>
              </Container>
            </Modal.Body>
          </Modal>
        </Tab.Container>
      </div>
    );
  }
}

export default SignupForm;
