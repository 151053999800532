import {
  AreaPine1,
  AreaPine2,
  AreaPine3,
  AreaPine4,
  AreaA,
  AreaB,
  AreaC,
  AreaD,
  AreaE,
  AreaF,
  AreaG,
  AreaH,
  AreaI,
  AreaJ,
  AreaK,
  AreaL,
  AreaM,
  AreaN,
  AreaO,
  AreaP,
  AreaQ,
  AreaR,
  AreaS,
  AreaT,
  AreaU,
  AreaV,
  AreaW,
  AreaX,
  AreaY,
  AreaZ,
} from "../components/mapSVGs/pineHill/pinePhase1AreaPart";
import { colors } from "./theme";
import {
  P2_0,
  P2_1,
  P2_2,
  P2_3,
  P2_4,
  P2_5,
  P2_6,
  P2_7,
  P2_8,
  P2_9,
  P2_10,
  P2_11,
  P2_12,
  P2_13,
  P2_14,
  P2_15,
  P2_16,
  P2_17,
  P2_18,
  P2_19,
  P2_20,
  P2_21,
  P2_22,
  P2_23,
  P2_24,
  P2_25,
  P2_26,
  P2_27,
  P2_28,
  P2_29,
  P2_30,
} from "../components/mapSVGs/pineHill/pinePhase2AreaPart";
import {
  P3_1,
  P3_2,
  P3_3,
  P3_4,
  P3_5,
  P3_6,
  P3_7,
  P3_8,
  P3_9,
  P3_10,
  P3_11,
  P3_12,
  P3_13,
  P3_14,
  P3_15,
  P3_16,
  P3_17,
  P3_18,
  P3_25,
  P3_26,
  P3_27,
  P3_28,
  P3_29,
  P3_30,
  P3_31,
  P3_32,
  P3_35,
  P3_36,
  P3_37,
  P3_38,
  P3_39,
  P3_40,
  P3_41,
  P3_42,
  P3_43,
  P3_44,
  P3_45,
  P3_46,
  P3_48,
  P3_49,
  P3_50,
  P3_51,
  P3_52,
  P3_53,
  P3_54,
  // P3_68,
  // P3_69,
} from "../components/mapSVGs/pineHill/pinePhase3AreaPart";
import {
  // P4_1,
  // P4_2,
  P4_3,
  P4_4,
  P4_5,
  // P4_6,
  // P4_7,
  // P4_8,
  P4_9,
  // P4_10,
  P4_11,
  P4_12,
  P4_13,
  P4_14,
  P4_15,
  P4_16,
  P4_17,
  P4_18,
  P4_19,
  P4_20,
  P4_21,
  P4_22,
  P4_23,
  P4_24,
  P4_25,
  P4_26,
  P4_27,
  P4_28,
  P4_29,
  P4_30,
  P4_31,
  P4_32,
  P4_33,
  P4_34,
  P4_35,
  P4_36,
  P4_37,
  P4_38,
  P4_39,
  P4_40,
  // P4_41,
  // P4_42,
  P4_43,
  P4_44,
  P4_45,
  P4_46,
  P4_47,
  P4_48,
  P4_49,
  // P4_50,
  P4_51,
  P4_52,
  P4_53,
  P4_54,
  P4_55,
  P4_56,
  P4_57,
  // P4_58,
  P4_59,
  P4_60,
  P4_61,
  P4_62,
  P4_63,
  P4_64,
  P4_65,
  P4_66,
  P4_67,
  P4_68,
  P4_69,
  P4_70,
  P4_71,
  P4_72,
  P4_73,
  P4_74,
  P4_75,
  P4_76,
  P4_77,
  P4_78,
  P4_79,
  P4_80,
  P4_81,
  P4_82,
  P4_83,
  P4_84,
  // P4_85,
  // P4_86,
  P4_87,
  // P4_88,
  // P4_89,
  // P4_90,
  // P4_91,
  // P4_92,
  // P4_93,
  // P4_94,
  // P4_95,
  // P4_96,
  // P4_97,
  // P4_98,
  // P4_99,
  // P4_100,
  // P4_101,
} from "../components/mapSVGs/pineHill/pinePhase4AreaPartPrevious";
import {
  C01,
  C02,
  C03,
  C04,
  C05,
  C06,
  C07,
  C08,
  C09,
  C10,
  C11,
  C12,
  C13,
  C14,
  C15,
  C16,
  C17,
  C18,
  C19,
  C20,
  C21,
  C22,
  // C23,
  C24,
  C25,
  C26,
  C27,
  C28,
  // C29,
  C30,
  C31,
  C32,
  C33,
  C34,
  C35,
  C36,
  C37,
  // C38,
  C39,
  // C40,
  // C41,
  C42,
  C43,
  C44,
  C45,
  C46,
  C47,
  C48,
  // C49,
  C50,
  C51,
  C52,
  C53,
  C54,
  C55,
  C56,
  C57,
  C58,
  // C59,
  C60,
  C61,
  C62,
  C63,
  C64,
  M01,
  M02,
  M03,
  // M04,
  M05,
  M06,
  M07,
  // M08,
  M09,
  // M10,
  M11,
  M12,
  // M13,
  M14,
  // M15,
  // M16,
  M17,
  M18,
  M19,
  M20,
  M21,
  M22,
  M23,
  M24,
  M25,
  M26,
  M27,
  M28,
  M29,
  M30,
  M31,
  M32,
  M33,
  // M34,
  M35,
  // M36,
  // M37,
  M38,
  M39,
  // M40,
  M41,
  M42,
  // M43,
  M44,
  M45,
  M46,
  M47,
  // M48,
  M49,
  M50,
  M51,
  M52,
  // M53,
  M54,
  M55,
  M56,
  M57,
  // M58,
  // M59,
  // M60,
  M61,
  M62,
  M63,
  // M64,
  // M65,
  M66,
  M67,
  // M68,
  // M69,
  M70,
  M71,
  M72,
  M73,
  // M77,
  D01,
  D02,
  D03,
  D04,
  D05,
  D06,
  D07,
  D08,
  D09,
  D10,
  D11,
  D12,
  D13,
  D14,
  D15,
  D16,
  D17,
  D18,
  D19,
  D20,
  D21,
  D22,
  D23,
  D24,
  D25,
  D26,
  D27,
  D28,
  D29,
  D30,
  D31,
  D32,
  D33,
  D34,
  D35,
  D36,
  D37,
  D38,
  D39,
  D40,
  D41,
  D42,
  D43,
  D44,
  D45,
  D46,
  // D47,
  D48,
  D49,
  D50,
  D51,
  D52,
  D53,
  D54,
  D55,
  D56,
  D58,
} from "../components/mapSVGs/pineHill/pinePhase4AreaPart";
const pink = colors.pink;
const yellow = colors.yellow;
const green = colors.green;

const areaSet = [
  {
    x: 1878,
    y: 1006,
    title: "A",
    badge: "",
    ax: 1787,
    ay: 877,
    area: AreaA,
    color: pink,
  },
  {
    x: 2160,
    y: 418,
    title: "B",
    badge: "",
    ax: 2079,
    ay: 320,
    area: AreaB,
    color: pink,
  },
  {
    x: 1954,
    y: 418,
    title: "C",
    badge: "",
    ax: 1870,
    ay: 305,
    area: AreaC,
    color: pink,
  },
  {
    x: 1753,
    y: 424,
    title: "D",
    badge: "",
    ax: 1623,
    ay: 289,
    area: AreaD,
    color: pink,
  },
  {
    x: 1506,
    y: 527,
    title: "E",
    badge: "",
    ax: 1382,
    ay: 415,
    area: AreaE,
    color: pink,
  },
  {
    x: 1310,
    y: 637,
    title: "F",
    badge: "",
    ax: 1153,
    ay: 500,
    area: AreaF,
    color: pink,
  },
  {
    x: 1100,
    y: 758,
    title: "G",
    badge: "",
    ax: 905,
    ay: 651,
    area: AreaG,
    color: green,
  },
  {
    x: 999,
    y: 961,
    title: "H",
    badge: "",
    ax: 905,
    ay: 883,
    area: AreaH,
    color: green,
  },
  {
    x: 1083,
    y: 1200,
    title: "I",
    badge: "",
    ax: 995,
    ay: 1031,
    area: AreaI,
    color: green,
  },
  {
    x: 1150,
    y: 1440,
    title: "J",
    badge: "",
    ax: 1060,
    ay: 1261,
    area: AreaJ,
    color: green,
  },
  {
    x: 1272,
    y: 1659,
    title: "K",
    badge: "",
    ax: 1133,
    ay: 1495,
    area: AreaK,
    color: green,
  },
  {
    x: 1501,
    y: 1608,
    title: "L",
    badge: "",
    ax: 1365,
    ay: 1440,
    area: AreaL,
    color: green,
  },
  {
    x: 1715,
    y: 1466,
    title: "M",
    badge: "",
    ax: 1627,
    ay: 1355,
    area: AreaM,
    color: pink,
  },
  {
    x: 1901,
    y: 1322,
    title: "N",
    badge: "",
    ax: 1770,
    ay: 1195,
    area: AreaN,
    color: pink,
  },
  {
    x: 2072,
    y: 1203,
    title: "O",
    badge: "",
    ax: 1933,
    ay: 1071,
    area: AreaO,
    color: pink,
  },
  {
    x: 2238,
    y: 1073,
    title: "P",
    badge: "",
    ax: 2092,
    ay: 889,
    area: AreaP,
    color: pink,
  },
  {
    x: 2453,
    y: 902,
    title: "Q",
    badge: "",
    ax: 2322,
    ay: 807,
    area: AreaQ,
    color: pink,
  },
  {
    x: 2091,
    y: 857,
    title: "R",
    badge: "",
    ax: 1933,
    ay: 721,
    area: AreaR,
    color: pink,
  },
  {
    x: 1939,
    y: 719,
    title: "S",
    badge: "",
    ax: 1792,
    ay: 671,
    area: AreaS,
    color: green,
  },
  {
    x: 1641,
    y: 771,
    title: "T",
    badge: "",
    ax: 1513,
    ay: 640,
    area: AreaT,
    color: green,
  },
  {
    x: 1477,
    y: 873,
    title: "U",
    badge: "",
    ax: 1350,
    ay: 741,
    area: AreaU,
    color: yellow,
  },
  {
    x: 1310,
    y: 968,
    title: "V",
    badge: "",
    ax: 1171,
    ay: 830,
    area: AreaV,
    color: pink,
  },
  {
    x: 1289,
    y: 1173,
    title: "W",
    badge: "",
    ax: 1171,
    ay: 1031,
    area: AreaW,
    color: pink,
  },
  {
    x: 1407,
    y: 1352,
    title: "X",
    badge: "",
    ax: 1294,
    ay: 1177,
    area: AreaX,
    color: green,
  },
  {
    x: 1578,
    y: 1244,
    title: "Y",
    badge: "",
    ax: 1529,
    ay: 1063,
    area: AreaY,
    color: yellow,
  },
  {
    x: 1728,
    y: 1132,
    title: "Z",
    badge: "",
    ax: 1653,
    ay: 965,
    area: AreaZ,
    color: green,
  },
  { ax: 2275, ay: 620, area: AreaPine1, color: green },
  { ax: 2646, ay: 301, area: AreaPine2, color: green },
  { ax: 2446, ay: 320, area: AreaPine3, color: green },
  { ax: 2275, ay: 336, area: AreaPine4, color: green },
];

const phase2AreaSet = [
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 897,
    ay: 576,
    area: P2_0,
    color: pink,
  },
  {
    x: 960,
    y: 580,
    title: "P2-1",
    badge: "",
    ax: 912,
    ay: 521,
    area: P2_1,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 810,
    ay: 597,
    area: P2_2,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 758,
    ay: 631,
    area: P2_3,
    color: pink,
  },
  {
    x: 810,
    y: 854,
    title: "P2-4",
    badge: "",
    ax: 748,
    ay: 680,
    area: P2_4,
    color: pink,
  },
  {
    x: 755,
    y: 910,
    title: "P2-5",
    badge: "",
    ax: 711,
    ay: 780.89,
    area: P2_5,
    color: pink,
  },
  {
    x: 666,
    y: 915,
    title: "P2-6",
    badge: "",
    ax: 644,
    ay: 847,
    area: P2_6,
    color: pink,
  },
  {
    x: 573,
    y: 941,
    title: "P2-7",
    badge: "",
    ax: 535,
    ay: 872,
    area: P2_7,
    color: pink,
  },
  {
    x: 462,
    y: 937,
    title: "P2-8",
    badge: "",
    ax: 403,
    ay: 859,
    area: P2_8,
    color: pink,
  },
  {
    x: 359,
    y: 915,
    title: "P2-9",
    badge: "",
    ax: 211,
    ay: 801,
    area: P2_9,
    color: pink,
  },
  {
    x: 303,
    y: 838,
    title: "P2-10",
    badge: "",
    ax: 130,
    ay: 741,
    area: P2_10,
    color: pink,
  },
  {
    x: 254,
    y: 758,
    title: "P2-11",
    badge: "",
    ax: 135,
    ay: 666,
    area: P2_11,
    color: pink,
  },
  {
    x: 249,
    y: 677,
    title: "P2-12",
    badge: "",
    ax: 142,
    ay: 599,
    area: P2_12,
    color: pink,
  },
  {
    x: 209,
    y: 590,
    title: "P2-13",
    badge: "",
    ax: 147,
    ay: 535,
    area: P2_13,
    color: pink,
  },
  {
    x: 225,
    y: 499,
    title: "P2-14",
    badge: "",
    ax: 153,
    ay: 444,
    area: P2_14,
    color: pink,
  },
  {
    x: 238,
    y: 381,
    title: "P2-15",
    badge: "",
    ax: 161,
    ay: 267,
    area: P2_15,
    color: pink,
  },
  {
    x: 336,
    y: 283,
    title: "P2-16",
    badge: "",
    ax: 237,
    ay: 161,
    area: P2_16,
    color: pink,
  },
  {
    x: 492,
    y: 273,
    title: "P2-17",
    badge: "",
    ax: 377,
    ay: 161,
    area: P2_17,
    color: pink,
  },
  {
    x: 577,
    y: 366,
    title: "P2-18",
    badge: "",
    ax: 527,
    ay: 307,
    area: P2_18,
    color: pink,
  },
  {
    x: 598,
    y: 460,
    title: "P2-19",
    badge: "",
    ax: 527,
    ay: 386,
    area: P2_19,
    color: pink,
  },
  {
    x: 638,
    y: 532,
    title: "P2-20",
    badge: "",
    ax: 557,
    ay: 451,
    area: P2_20,
    color: pink,
  },
  {
    x: 662,
    y: 600,
    title: "P2-21",
    badge: "",
    ax: 586,
    ay: 518,
    area: P2_21,
    color: pink,
  },
  {
    x: 644,
    y: 685,
    title: "P2-22",
    badge: "",
    ax: 574,
    ay: 591,
    area: P2_22,
    color: pink,
  },
  {
    x: 631,
    y: 772,
    title: "P2-23",
    badge: "",
    ax: 574,
    ay: 712,
    area: P2_23,
    color: pink,
  },
  {
    x: 801,
    y: 520,
    title: "P2-24",
    badge: "",
    ax: 772,
    ay: 409,
    area: P2_24,
    color: pink,
  },
  {
    x: 846,
    y: 455,
    title: "P2-25",
    badge: "",
    ax: 797,
    ay: 345,
    area: P2_25,
    color: pink,
  },
  {
    x: 906,
    y: 388,
    title: "P2-26",
    badge: "",
    ax: 847,
    ay: 278,
    area: P2_26,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 444,
    ay: 651,
    area: P2_27,
    color: pink,
  },
  {
    x: 459,
    y: 643,
    title: "P2-28",
    badge: "",
    ax: 420,
    ay: 573,
    area: P2_28,
    color: pink,
  },
  {
    x: 433,
    y: 566,
    title: "P2-29",
    badge: "",
    ax: 386,
    ay: 477,
    area: P2_29,
    color: pink,
  },
  {
    x: 412,
    y: 472,
    title: "P2-30",
    badge: "",
    ax: 359,
    ay: 410,
    area: P2_30,
    color: pink,
  },
];

const phase3AreaSet = [
  {
    x: 578,
    y: 788,
    title: "",
    badge: "",
    ax: 717,
    ay: 765,
    area: P3_1,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 672,
    ay: 755,
    area: P3_2,
    color: pink,
  },
  {
    x: 643,
    y: 776,
    title: "P3-3",
    badge: "",
    ax: 626,
    ay: 746,
    area: P3_3,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 581,
    ay: 735,
    area: P3_4,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 536,
    ay: 725,
    area: P3_5,
    color: pink,
  },
  {
    x: 504,
    y: 743,
    title: "P3-6",
    badge: "",
    ax: 493,
    ay: 716,
    area: P3_6,
    color: pink,
  },
  {
    x: 439,
    y: 728,
    title: "P3-7",
    badge: "",
    ax: 427,
    ay: 702,
    area: P3_7,
    color: pink,
  },
  {
    x: 517,
    y: 680,
    title: "P3-8",
    badge: "",
    ax: 504,
    ay: 652,
    area: P3_8,
    color: pink,
  },
  {
    x: 563,
    y: 688,
    title: "P3-9",
    badge: "",
    ax: 551,
    ay: 660,
    area: P3_9,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 595,
    ay: 671,
    area: P3_10,
    color: pink,
  },
  {
    x: 661,
    y: 713,
    title: "P3-11",
    badge: "",
    ax: 641,
    ay: 680,
    area: P3_11,
    color: pink,
  },
  {
    x: 731,
    y: 712,
    title: "P3-12",
    badge: "",
    ax: 701,
    ay: 671,
    area: P3_12,
    color: pink,
  },
  {
    x: 744,
    y: 654,
    title: "P3-13",
    badge: "",
    ax: 720,
    ay: 627,
    area: P3_13,
    color: pink,
  },
  {
    x: 757,
    y: 606,
    title: "P3-14",
    badge: "",
    ax: 736,
    ay: 578,
    area: P3_14,
    color: pink,
  },
  {
    x: 771,
    y: 553,
    title: "P3-15",
    badge: "",
    ax: 747,
    ay: 532,
    area: P3_15,
    color: pink,
  },
  {
    x: 771,
    y: 497,
    title: "P3-16",
    badge: "",
    ax: 741,
    ay: 463,
    area: P3_16,
    color: pink,
  },
  {
    x: 758,
    y: 448,
    title: "P3-17",
    badge: "",
    ax: 729,
    ay: 421,
    area: P3_17,
    color: pink,
  },
  {
    x: 747,
    y: 399,
    title: "P3-18",
    badge: "",
    ax: 711,
    ay: 367,
    area: P3_18,
    color: pink,
  },

  {
    x: 542,
    y: 585,
    title: "P3-25",
    badge: "",
    ax: 530,
    ay: 543,
    area: P3_25,
    color: pink,
  },
  {
    x: 587,
    y: 593,
    title: "P3-26",
    badge: "",
    ax: 575,
    ay: 552,
    area: P3_26,
    color: pink,
  },
  {
    x: 649,
    y: 632,
    title: "P3-27",
    badge: "",
    ax: 620,
    ay: 609,
    area: P3_27,
    color: pink,
  },
  {
    x: 659,
    y: 586,
    title: "P3-28",
    badge: "",
    ax: 629,
    ay: 563,
    area: P3_28,
    color: pink,
  },
  {
    x: 671,
    y: 537,
    title: "P3-29",
    badge: "",
    ax: 641,
    ay: 518,
    area: P3_29,
    color: pink,
  },
  {
    x: 660,
    y: 488,
    title: "P3-30",
    badge: "",
    ax: 631,
    ay: 458,
    area: P3_30,
    color: pink,
  },
  {
    x: 646,
    y: 440,
    title: "P3-31",
    badge: "",
    ax: 622,
    ay: 404,
    area: P3_31,
    color: pink,
  },
  {
    x: 623,
    y: 391,
    title: "P3-32",
    badge: "",
    ax: 593,
    ay: 358,
    area: P3_32,
    color: pink,
  },

  {
    x: 495,
    y: 406,
    title: "P3-35",
    badge: "",
    ax: 457,
    ay: 371,
    area: P3_35,
    color: pink,
  },
  {
    x: 417,
    y: 314,
    title: "P3-36",
    badge: "",
    ax: 371,
    ay: 234,
    area: P3_36,
    color: pink,
  },
  {
    x: 449,
    y: 272,
    title: "P3-37",
    badge: "",
    ax: 398,
    ay: 226,
    area: P3_37,
    color: pink,
  },
  {
    x: 566,
    y: 345,
    title: "P3-38",
    badge: "",
    ax: 531,
    ay: 305,
    area: P3_38,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 518,
    ay: 266,
    area: P3_39,
    color: pink,
  },
  {
    x: 519,
    y: 258,
    title: "P3-40",
    badge: "",
    ax: 489,
    ay: 224,
    area: P3_40,
    color: pink,
  },
  {
    x: 0,
    y: 0,
    title: "",
    badge: "",
    ax: 460,
    ay: 181,
    area: P3_41,
    color: pink,
  },
  {
    x: 464,
    y: 160,
    title: "P3-42",
    badge: "",
    ax: 446,
    ay: 126,
    area: P3_42,
    color: pink,
  },
  {
    x: 405,
    y: 155,
    title: "P3-43",
    badge: "",
    ax: 398,
    ay: 116,
    area: P3_43,
    color: pink,
  },
  {
    x: 355,
    y: 154,
    title: "P3-44",
    badge: "",
    ax: 348,
    ay: 112,
    area: P3_44,
    color: pink,
  },
  {
    x: 69,
    y: 165,
    title: "P3-45",
    badge: "",
    ax: 37,
    ay: 109,
    area: P3_45,
    color: pink,
  },
  {
    x: 132,
    y: 214,
    title: "P3-46",
    badge: "",
    ax: 84,
    ay: 137,
    area: P3_46,
    color: pink,
  },
  {
    x: 176,
    y: 563,
    title: "P3-48",
    badge: "",
    ax: 155,
    ay: 479,
    area: P3_48,
    color: pink,
  },
  {
    x: 239,
    y: 500,
    title: "P3-49",
    badge: "",
    ax: 169,
    ay: 367,
    area: P3_49,
    color: pink,
  },
  {
    x: 281,
    y: 539,
    title: "P3-50",
    badge: "",
    ax: 219,
    ay: 501,
    area: P3_50,
    color: pink,
  },
  {
    x: 270,
    y: 603,
    title: "P3-51",
    badge: "",
    ax: 239,
    ay: 538,
    area: P3_51,
    color: pink,
  },
  {
    x: 311,
    y: 650,
    title: "P3-52",
    badge: "",
    ax: 284,
    ay: 606,
    area: P3_52,
    color: pink,
  },
  {
    x: 372,
    y: 703,
    title: "P3-53",
    badge: "",
    ax: 338,
    ay: 654,
    area: P3_53,
    color: pink,
  },
  {
    x: 267,
    y: 723,
    title: "P3-54",
    badge: "",
    ax: 235,
    ay: 669,
    area: P3_54,
    color: pink,
  },
  // {
  //   x: 65,
  //   y: 539,
  //   title: "P3-68",
  //   badge: "",
  //   ax: 18,
  //   ay: 472,
  //   area: P3_68,
  //   color: pink,
  // },
  // {
  //   x: 134,
  //   y: 567,
  //   title: "P3-69",
  //   badge: "",
  //   ax: 80,
  //   ay: 494,
  //   area: P3_69,
  //   color: pink,
  // },
];

// here x and y for black background and ax and ay for area image
const phase4AreaSet = [
  {
    x: 1389,
    y: 1920,
    title: "C01",
    badge: "",
    ax: 1362,
    ay: 1930,
    rotate: 39,
    area: C01,
    color: pink,
    type: "chalet",
  },
  {
    x: 1374,
    y: 1885,
    title: "C02",
    badge: "",
    ax: 1338,
    ay: 1882,
    area: C02,
    color: pink,
    type: "chalet",
  },
  {
    x: 1368,
    y: 1799,
    title: "C03",
    badge: "",
    ax: 1334,
    ay: 1795,
    area: C03,
    color: pink,
    type: "chalet",
  },
  {
    x: 1473,
    y: 1874,
    title: "C04",
    badge: "",
    ax: 1441,
    ay: 1873,
    area: C04,
    color: pink,
    type: "chalet",
  },
  {
    x: 1533,
    y: 1982,
    title: "C05",
    badge: "",
    ax: 1531,
    ay: 1943,
    area: C05,
    color: pink,
    type: "chalet",
  },
  {
    x: 1229,
    y: 1730,
    title: "C06",
    badge: "",
    ax: 1210,
    ay: 1750,
    area: C06,
    color: pink,
    type: "chalet",
  },
  {
    x: 1176,
    y: 1641,
    title: "C07",
    badge: "",
    ax: 1212,
    ay: 1665,
    area: C07,
    color: pink,
    type: "chalet",
  },
  {
    x: 1147,
    y: 1827,
    title: "C08",
    badge: "",
    ax: 1148,
    ay: 1780,
    area: C08,
    color: pink,
    type: "chalet",
  },
  {
    x: 1093,
    y: 1643,
    title: "C09",
    badge: "",
    ax: 1109,
    ay: 1671,
    area: C09,
    color: pink,
    type: "chalet",
  },
  {
    x: 1010,
    y: 1692,
    title: "C10",
    badge: "",
    ax: 1021,
    ay: 1720,
    area: C10,
    color: pink,
    type: "chalet",
  },
  {
    x: 970,
    y: 1823,
    title: "C11",
    badge: "",
    ax: 970,
    ay: 1852,
    area: C11,
    color: pink,
    type: "chalet",
  },
  {
    x: 1060,
    y: 1784,
    title: "C12",
    badge: "",
    ax: 1064,
    ay: 1812,
    area: C12,
    color: pink,
    type: "chalet",
  },
  {
    x: 928,
    y: 1710,
    title: "C13",
    badge: "",
    ax: 943,
    ay: 1740,
    area: C13,
    color: pink,
    type: "chalet",
  },
  {
    x: 864,
    y: 1792,
    title: "C14",
    badge: "",
    ax: 875,
    ay: 1816,
    area: C14,
    color: pink,
    type: "chalet",
  },
  {
    x: 793,
    y: 1773,
    title: "C15",
    badge: "",
    ax: 804,
    ay: 1800,
    area: C15,
    color: pink,
    type: "chalet",
  },
  {
    x: 1450,
    y: 1992,
    title: "C16",
    badge: "",
    ax: 1471,
    ay: 1967,
    area: C16,
    color: pink,
    type: "chalet",
  },
  {
    x: 1506,
    y: 2074,
    title: "C17",
    badge: "",
    ax: 1477,
    ay: 2078,
    area: C17,
    color: pink,
    type: "chalet",
  },
  {
    x: 1515,
    y: 2130,
    title: "C18",
    badge: "",
    ax: 1484,
    ay: 2133,
    area: C18,
    color: pink,
    type: "chalet",
  },
  {
    x: 1560,
    y: 2167,
    title: "C19",
    badge: "",
    ax: 1530,
    ay: 2173,
    area: C19,
    color: pink,
    type: "chalet",
  },
  {
    x: 1575,
    y: 2221,
    title: "C20",
    badge: "",
    ax: 1607,
    ay: 2194,
    area: C20,
    color: pink,
    type: "chalet",
  },
  {
    x: 1640,
    y: 2265,
    title: "C21",
    badge: "",
    ax: 1665,
    ay: 2239,
    area: C21,
    color: pink,
    type: "chalet",
  },
  {
    x: 1647,
    y: 2324,
    title: "C22",
    badge: "",
    ax: 1680,
    ay: 2296,
    area: C22,
    color: pink,
    type: "chalet",
  },
  // {
  //   x: 1150,
  //   y: 1374,
  //   title: "C23",
  //   badge: "",
  //   ax: 1123,
  //   ay: 1371,
  //   area: C23,
  //   color: pink,
  //   type: "chalet",
  // },
  {
    x: 1779,
    y: 2452,
    title: "C24",
    badge: "",
    ax: 1742,
    ay: 2454,
    area: C24,
    color: pink,
    type: "chalet",
  },
  {
    x: 1771,
    y: 2511,
    title: "C25",
    badge: "",
    ax: 1811,
    ay: 2497,
    area: C25,
    color: pink,
    type: "chalet",
  },
  {
    x: 1788,
    y: 2566,
    title: "C26",
    badge: "",
    ax: 1830,
    ay: 2556,
    area: C26,
    color: pink,
    type: "chalet",
  },
  {
    x: 1807,
    y: 2631,
    title: "C27",
    badge: "",
    ax: 1853,
    ay: 2621,
    area: C27,
    color: pink,
    type: "chalet",
  },
  {
    x: 1808,
    y: 2694,
    title: "C28",
    badge: "",
    ax: 1845,
    ay: 2696,
    area: C28,
    color: pink,
    type: "chalet",
  },
  // {
  //   x: 1252,
  //   y: 1668,
  //   title: "C29",
  //   badge: "",
  //   ax: 1227,
  //   ay: 1656,
  //   area: C29,
  //   color: pink,
  //   type: "chalet",
  // },
  {
    x: 1777,
    y: 2748,
    title: "C30",
    badge: "",
    ax: 1801,
    ay: 2767,
    area: C30,
    color: pink,
    type: "chalet",
  },
  {
    x: 1752,
    y: 2818,
    title: "C31",
    badge: "",
    ax: 1770,
    ay: 2836,
    area: C31,
    color: pink,
    type: "chalet",
  },
  {
    x: 1677,
    y: 2844,
    title: "C32",
    badge: "",
    ax: 1693,
    ay: 2870,
    area: C32,
    color: pink,
    type: "chalet",
  },
  {
    x: 1559,
    y: 2442,
    title: "C33",
    badge: "",
    ax: 1545,
    ay: 2399,
    area: C33,
    color: pink,
    type: "chalet",
  },
  {
    x: 1640,
    y: 2561,
    title: "C34",
    badge: "",
    ax: 1636,
    ay: 2522,
    area: C34,
    color: pink,
    type: "chalet",
  },
  {
    x: 1464,
    y: 2444,
    title: "C35",
    badge: "",
    ax: 1467,
    ay: 2410,
    area: C35,
    color: pink,
    type: "chalet",
  },
  {
    x: 1500,
    y: 2524,
    title: "C36",
    badge: "",
    ax: 1528,
    ay: 2488,
    area: C36,
    color: pink,
    type: "chalet",
  },
  {
    x: 1563,
    y: 2590,
    title: "C37",
    badge: "",
    ax: 1531,
    ay: 2571,
    area: C37,
    color: pink,
    type: "chalet",
  },
  // {
  //   x: 1090,
  //   y: 1603,
  //   title: "C38",
  //   badge: "",
  //   ax: 1070,
  //   ay: 1580,
  //   area: C38,
  //   color: pink,
  //   type: "chalet",
  // },
  {
    x: 1479,
    y: 2626,
    title: "C39",
    badge: "",
    ax: 1472,
    ay: 2586,
    area: C39,
    color: pink,
    type: "chalet",
  },
  // {
  //   x: 1004,
  //   y: 1606,
  //   title: "C40",
  //   badge: "",
  //   ax: 1012,
  //   ay: 1581,
  //   area: C40,
  //   color: pink,
  //   type: "chalet",
  // },
  // {
  //   x: 975,
  //   y: 1508,
  //   title: "C41",
  //   badge: "",
  //   ax: 966,
  //   ay: 1476,
  //   area: C41,
  //   color: pink,
  //   type: "chalet",
  // },
  {
    x: 1350,
    y: 2486,
    title: "C42",
    badge: "",
    ax: 1344,
    ay: 2451,
    area: C42,
    color: pink,
    type: "chalet",
  },
  {
    x: 1432,
    y: 2539,
    title: "C43",
    badge: "",
    ax: 1409,
    ay: 2504,
    area: C43,
    color: pink,
    type: "chalet",
  },
  {
    x: 1382,
    y: 2627,
    title: "C44",
    badge: "",
    ax: 1378,
    ay: 2586,
    area: C44,
    color: pink,
    type: "chalet",
  },
  {
    x: 1188,
    y: 2666,
    title: "C45",
    badge: "",
    ax: 1212,
    ay: 2686,
    area: C45,
    color: pink,
    type: "chalet",
  },
  {
    x: 1107,
    y: 2749,
    title: "C46",
    badge: "",
    ax: 1073,
    ay: 2759,
    area: C46,
    color: pink,
    type: "chalet",
  },
  {
    x: 1234,
    y: 2635,
    title: "C47",
    badge: "",
    ax: 1266,
    ay: 2657,
    area: C47,
    color: pink,
    type: "chalet",
  },
  {
    x: 1282,
    y: 2605,
    title: "C48",
    badge: "",
    ax: 1305,
    ay: 2622,
    area: C48,
    color: pink,
    type: "chalet",
  },
  // {
  //   x: 834,
  //   y: 1536,
  //   title: "C49",
  //   badge: "",
  //   ax: 834,
  //   ay: 1505,
  //   area: C49,
  //   color: pink,
  //   type: "chalet",
  // },
  {
    x: 1221,
    y: 2574,
    title: "C50",
    badge: "",
    ax: 1194,
    ay: 2545,
    area: C50,
    color: pink,
    type: "chalet",
  },
  {
    x: 1118,
    y: 2609,
    title: "C51",
    badge: "",
    ax: 1117,
    ay: 2578,
    area: C51,
    color: pink,
    type: "chalet",
  },
  {
    x: 1268,
    y: 2780,
    title: "C52",
    badge: "",
    ax: 1274,
    ay: 2802,
    area: C52,
    color: pink,
    type: "chalet",
  },
  {
    x: 1348,
    y: 2760,
    title: "C53",
    badge: "",
    ax: 1347,
    ay: 2790,
    area: C53,
    color: pink,
    type: "chalet",
  },
  {
    x: 1436,
    y: 2768,
    title: "C54",
    badge: "",
    ax: 1433,
    ay: 2795,
    area: C54,
    color: pink,
    type: "chalet",
  },
  {
    x: 1510,
    y: 2743,
    title: "C55",
    badge: "",
    ax: 1513,
    ay: 2769,
    area: C55,
    color: pink,
    type: "chalet",
  },
  {
    x: 1587,
    y: 2715,
    title: "C56",
    badge: "",
    ax: 1593,
    ay: 2739,
    area: C56,
    color: pink,
    type: "chalet",
  },
  {
    x: 1640,
    y: 2668,
    title: "C57",
    badge: "",
    ax: 1663,
    ay: 2682,
    area: C57,
    color: pink,
    type: "chalet",
  },
  {
    x: 1037,
    y: 2574,
    title: "C58",
    badge: "",
    ax: 1045,
    ay: 2534,
    area: C58,
    color: pink,
    type: "chalet",
  },
  // {
  //   x: 662,
  //   y: 1598,
  //   title: "C59",
  //   badge: "",
  //   ax: 661,
  //   ay: 1571,
  //   area: C59,
  //   color: pink,
  //   type: "chalet",
  // },
  {
    x: 970,
    y: 2422,
    title: "C60",
    badge: "",
    ax: 959,
    ay: 2448,
    area: C60,
    color: pink,
    type: "chalet",
  },
  {
    x: 952,
    y: 2548,
    title: "C61",
    badge: "",
    ax: 940,
    ay: 2572,
    area: C61,
    color: pink,
    type: "chalet",
  },
  {
    x: 797,
    y: 2529,
    title: "C62",
    badge: "",
    ax: 790,
    ay: 2491,
    area: C62,
    color: pink,
    type: "chalet",
  },
  {
    x: 874,
    y: 2493,
    title: "C63",
    badge: "",
    ax: 889,
    ay: 2520,
    area: C63,
    color: pink,
    type: "chalet",
  },
  {
    x: 886,
    y: 2391,
    title: "C64",
    badge: "",
    ax: 886,
    ay: 2415,
    area: C64,
    color: pink,
    type: "chalet",
  },
  {
    x: 1214,
    y: 1929,
    title: "M01",
    badge: "",
    ax: 1216,
    ay: 1957,
    area: M01,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1070,
    y: 1994,
    title: "M02",
    badge: "",
    ax: 1119,
    ay: 1996,
    area: M02,
    color: pink,
    type: "minichalet",
  },
  {
    x: 955,
    y: 1986,
    title: "M03",
    badge: "",
    ax: 965,
    ay: 1954,
    area: M03,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 443,
  //   y: 1056,
  //   title: "M04",
  //   badge: "",
  //   ax: 474,
  //   ay: 1039,
  //   area: M04,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 857,
    y: 1982,
    title: "M05",
    badge: "",
    ax: 849,
    ay: 1950,
    area: M05,
    color: pink,
    type: "minichalet",
  },
  {
    x: 715,
    y: 2034,
    title: "M06",
    badge: "",
    ax: 756,
    ay: 2011,
    area: M06,
    color: pink,
    type: "minichalet",
  },
  {
    x: 534,
    y: 1873,
    title: "M07",
    badge: "",
    ax: 578,
    ay: 1860,
    area: M07,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 317,
  //   y: 1035,
  //   title: "M08",
  //   badge: "",
  //   ax: 292,
  //   ay: 1032,
  //   area: M08,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 485,
    y: 2021,
    title: "M09",
    badge: "",
    ax: 499,
    ay: 1984,
    area: M09,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 329,
  //   y: 1093,
  //   title: "M10",
  //   badge: "",
  //   ax: 362,
  //   ay: 1090,
  //   area: M10,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 717,
    y: 2100,
    title: "M11",
    badge: "",
    ax: 745,
    ay: 2100,
    area: M11,
    color: pink,
    type: "minichalet",
  },
  {
    x: 931,
    y: 2040,
    title: "M12",
    badge: "",
    ax: 904,
    ay: 2048,
    area: M12,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 579,
  //   y: 1168,
  //   title: "M13",
  //   badge: "",
  //   ax: 559,
  //   ay: 1164,
  //   area: M13,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 891,
    y: 2138,
    title: "M14",
    badge: "",
    ax: 880,
    ay: 2122,
    area: M14,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 743,
  //   y: 1136,
  //   title: "M15",
  //   badge: "",
  //   ax: 770,
  //   ay: 1121,
  //   area: M15,
  //   color: pink,
  //   type: "minichalet",
  // },
  // {
  //   x: 754,
  //   y: 1172,
  //   title: "M16",
  //   badge: "",
  //   ax: 782,
  //   ay: 1172,
  //   area: M16,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 1195,
    y: 2070,
    title: "M17",
    badge: "",
    ax: 1175,
    ay: 2075,
    area: M17,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1217,
    y: 2172,
    title: "M18",
    badge: "",
    ax: 1227,
    ay: 2146,
    area: M18,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1305,
    y: 2142,
    title: "M19",
    badge: "",
    ax: 1302,
    ay: 2116,
    area: M19,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1058,
    y: 2220,
    title: "M20",
    badge: "",
    ax: 1103,
    ay: 2193,
    area: M20,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1049,
    y: 2110,
    title: "M21",
    badge: "",
    ax: 1076,
    ay: 2117,
    area: M21,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1036,
    y: 2318,
    title: "M22",
    badge: "",
    ax: 1053,
    ay: 2285,
    area: M22,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1135,
    y: 2466,
    title: "M23",
    badge: "",
    ax: 1103,
    ay: 2452,
    area: M23,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1386,
    y: 2198,
    title: "M24",
    badge: "",
    ax: 1422,
    ay: 2215,
    area: M24,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1339,
    y: 2292,
    title: "M25",
    badge: "",
    ax: 1382,
    ay: 2305,
    area: M25,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1476,
    y: 2309,
    title: "M26",
    badge: "",
    ax: 1466,
    ay: 2280,
    area: M26,
    color: pink,
    type: "minichalet",
  },
  {
    x: 1287,
    y: 2388,
    title: "M27",
    badge: "",
    ax: 1320,
    ay: 2364,
    area: M27,
    color: pink,
    type: "minichalet",
  },
  {
    x: 880,
    y: 2290,
    title: "M28",
    badge: "",
    ax: 900,
    ay: 2306,
    area: M28,
    color: pink,
    type: "minichalet",
  },
  {
    x: 970,
    y: 2153,
    title: "M29",
    badge: "",
    ax: 1011,
    ay: 2152,
    area: M29,
    color: pink,
    type: "minichalet",
  },
  {
    x: 953,
    y: 2267,
    title: "M30",
    badge: "",
    ax: 978,
    ay: 2233,
    area: M30,
    color: pink,
    type: "minichalet",
  },
  {
    x: 805,
    y: 2349,
    title: "M31",
    badge: "",
    ax: 838,
    ay: 2349,
    area: M31,
    color: pink,
    type: "minichalet",
  },
  {
    x: 763,
    y: 2391,
    title: "M32",
    badge: "",
    ax: 738,
    ay: 2372,
    area: M32,
    color: pink,
    type: "minichalet",
  },
  {
    x: 767,
    y: 2258,
    title: "M33",
    badge: "",
    ax: 722,
    ay: 2266,
    area: M33,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 469,
  //   y: 1334,
  //   title: "M34",
  //   badge: "",
  //   ax: 450,
  //   ay: 1318,
  //   area: M34,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 847,
    y: 2236,
    title: "M35",
    badge: "",
    ax: 844,
    ay: 2211,
    area: M35,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 417,
  //   y: 1203,
  //   title: "M36",
  //   badge: "",
  //   ax: 452,
  //   ay: 1198,
  //   area: M36,
  //   color: pink,
  //   type: "minichalet",
  // },
  // {
  //   x: 438,
  //   y: 1235,
  //   title: "M37",
  //   badge: "",
  //   ax: 472,
  //   ay: 1230,
  //   area: M37,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 705,
    y: 2204,
    title: "M38",
    badge: "",
    ax: 755,
    ay: 2197,
    area: M38,
    color: pink,
    type: "minichalet",
  },
  {
    x: 575,
    y: 2009,
    title: "M39",
    badge: "",
    ax: 601,
    ay: 2025,
    area: M39,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 301,
  //   y: 1151,
  //   title: "M40",
  //   badge: "",
  //   ax: 333,
  //   ay: 1142,
  //   area: M40,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 557,
    y: 2099,
    title: "M41",
    badge: "",
    ax: 605,
    ay: 2107,
    area: M41,
    color: pink,
    type: "minichalet",
  },
  {
    x: 486,
    y: 2157,
    title: "M42",
    badge: "",
    ax: 542,
    ay: 2158,
    area: M42,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 356,
  //   y: 1230,
  //   title: "M43",
  //   badge: "",
  //   ax: 342,
  //   ay: 1240,
  //   area: M43,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 583,
    y: 2268,
    title: "M44",
    badge: "",
    ax: 594,
    ay: 2239,
    area: M44,
    color: pink,
    type: "minichalet",
  },
  {
    x: 465,
    y: 2220,
    title: "M45",
    badge: "",
    ax: 481,
    ay: 2247,
    area: M45,
    color: pink,
    type: "minichalet",
  },
  {
    x: 501,
    y: 2288,
    title: "M46",
    badge: "",
    ax: 508,
    ay: 2306,
    area: M46,
    color: pink,
    type: "minichalet",
  },
  {
    x: 439,
    y: 3069,
    title: "M47",
    badge: "",
    ax: 418,
    ay: 3101,
    area: M47,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 139,
  //   y: 1949,
  //   title: "M48",
  //   badge: "",
  //   ax: 159,
  //   ay: 1957,
  //   area: M48,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 343,
    y: 3119,
    title: "M49",
    badge: "",
    ax: 331,
    ay: 3148,
    area: M49,
    color: pink,
    type: "minichalet",
  },
  {
    x: 559,
    y: 3084,
    title: "M50",
    badge: "",
    ax: 539,
    ay: 3105,
    area: M50,
    color: pink,
    type: "minichalet",
  },
  {
    x: 442,
    y: 3169,
    title: "M51",
    badge: "",
    ax: 429,
    ay: 3194,
    area: M51,
    color: pink,
    type: "minichalet",
  },
  {
    x: 540,
    y: 3189,
    title: "M52",
    badge: "",
    ax: 550,
    ay: 3210,
    area: M52,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 308,
  //   y: 1979,
  //   title: "M53",
  //   badge: "",
  //   ax: 316,
  //   ay: 1992,
  //   area: M53,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 623,
    y: 3121,
    title: "M54",
    badge: "",
    ax: 642,
    ay: 3147,
    area: M54,
    color: pink,
    type: "minichalet",
  },
  {
    x: 461,
    y: 3262,
    title: "M55",
    badge: "",
    ax: 477,
    ay: 3287,
    area: M55,
    color: pink,
    type: "minichalet",
  },
  {
    x: 433,
    y: 3358,
    title: "M56",
    badge: "",
    ax: 450,
    ay: 3326,
    area: M56,
    color: pink,
    type: "minichalet",
  },
  {
    x: 360,
    y: 3270,
    title: "M57",
    badge: "",
    ax: 386,
    ay: 3242,
    area: M57,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 157,
  //   y: 2068,
  //   title: "M58",
  //   badge: "",
  //   ax: 137,
  //   ay: 2068,
  //   area: M58,
  //   color: pink,
  //   type: "minichalet",
  // },
  // {
  //   x: 251,
  //   y: 2130,
  //   title: "M59",
  //   badge: "",
  //   ax: 261,
  //   ay: 2108,
  //   area: M59,
  //   color: pink,
  //   type: "minichalet",
  // },
  // {
  //   x: 276,
  //   y: 2170,
  //   title: "M60",
  //   badge: "",
  //   ax: 275,
  //   ay: 2146,
  //   area: M60,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 607,
    y: 3262,
    title: "M61",
    badge: "",
    ax: 638,
    ay: 3253,
    area: M61,
    color: pink,
    type: "minichalet",
  },
  {
    x: 583,
    y: 3326,
    title: "M62",
    badge: "",
    ax: 621,
    ay: 3323,
    area: M62,
    color: pink,
    type: "minichalet",
  },
  {
    x: 297,
    y: 3322,
    title: "M63",
    badge: "",
    ax: 315,
    ay: 3351,
    area: M63,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 135,
  //   y: 2167,
  //   title: "M64",
  //   badge: "",
  //   ax: 143,
  //   ay: 2144,
  //   area: M64,
  //   color: pink,
  //   type: "minichalet",
  // },
  // {
  //   x: 192,
  //   y: 2185,
  //   title: "M65",
  //   badge: "",
  //   ax: 200,
  //   ay: 2162,
  //   area: M65,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 550,
    y: 3554,
    title: "M66",
    badge: "",
    ax: 541,
    ay: 3526,
    area: M66,
    color: pink,
    type: "minichalet",
  },
  {
    x: 561,
    y: 3405,
    title: "M67",
    badge: "",
    ax: 546,
    ay: 3430,
    area: M67,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 248,
  //   y: 2304,
  //   title: "M68",
  //   badge: "",
  //   ax: 277,
  //   ay: 2284,
  //   area: M68,
  //   color: pink,
  //   type: "minichalet",
  // },
  // {
  //   x: 297,
  //   y: 2341,
  //   title: "M69",
  //   badge: "",
  //   ax: 330,
  //   ay: 2333,
  //   area: M69,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 581,
    y: 3635,
    title: "M70",
    badge: "",
    ax: 609,
    ay: 3651,
    area: M70,
    color: pink,
    type: "minichalet",
  },
  {
    x: 648,
    y: 3726,
    title: "M71",
    badge: "",
    ax: 670,
    ay: 3735,
    area: M71,
    color: pink,
    type: "minichalet",
  },
  {
    x: 628,
    y: 3526,
    title: "M72",
    badge: "",
    ax: 655,
    ay: 3537,
    area: M72,
    color: pink,
    type: "minichalet",
  },
  {
    x: 677,
    y: 3621,
    title: "M73",
    badge: "",
    ax: 699,
    ay: 3637,
    area: M73,
    color: pink,
    type: "minichalet",
  },
  // {
  //   x: 333,
  //   y: 1920,
  //   title: "M77",
  //   badge: "",
  //   ax: 313,
  //   ay: 1911,
  //   area: M77,
  //   color: pink,
  //   type: "minichalet",
  // },
  {
    x: 440,
    y: 2340,
    title: "D01",
    badge: "",
    ax: 475,
    ay: 2340,
    area: D01,
    color: pink,
    type: "dome",
  },
  {
    x: 426,
    y: 2372,
    title: "D02",
    badge: "",
    ax: 470,
    ay: 2372,
    area: D02,
    color: pink,
    type: "dome",
  },
  {
    x: 423,
    y: 2403,
    title: "D03",
    badge: "",
    ax: 465,
    ay: 2403,
    area: D03,
    color: pink,
    type: "dome",
  },
  {
    x: 582,
    y: 2404,
    title: "D04",
    badge: "",
    ax: 562,
    ay: 2422,
    area: D04,
    color: pink,
    type: "dome",
  },
  {
    x: 594,
    y: 2443,
    title: "D05",
    badge: "",
    ax: 585,
    ay: 2464,
    area: D05,
    color: pink,
    type: "dome",
  },
  {
    x: 631,
    y: 2428,
    title: "D06",
    badge: "",
    ax: 633,
    ay: 2450,
    area: D06,
    color: pink,
    type: "dome",
  },
  {
    x: 691,
    y: 2433,
    title: "D07",
    badge: "",
    ax: 669,
    ay: 2452,
    area: D07,
    color: pink,
    type: "dome",
  },
  {
    x: 741,
    y: 2458,
    title: "D08",
    badge: "",
    ax: 704,
    ay: 2469,
    area: D08,
    color: pink,
    type: "dome",
  },
  {
    x: 756,
    y: 2492,
    title: "D09",
    badge: "",
    ax: 726,
    ay: 2502,
    area: D09,
    color: pink,
    type: "dome",
  },
  {
    x: 404,
    y: 2480,
    title: "D10",
    badge: "",
    ax: 419,
    ay: 2495,
    area: D10,
    color: pink,
    type: "dome",
  },
  {
    x: 433,
    y: 2442,
    title: "D11",
    badge: "",
    ax: 440,
    ay: 2460,
    area: D11,
    color: pink,
    type: "dome",
  },
  {
    x: 477,
    y: 2442,
    title: "D12",
    badge: "",
    ax: 480,
    ay: 2464,
    area: D12,
    color: pink,
    type: "dome",
  },
  {
    x: 402,
    y: 2656,
    title: "D13",
    badge: "",
    ax: 438,
    ay: 2659,
    area: D13,
    color: pink,
    type: "dome",
  },
  {
    x: 418,
    y: 2590,
    title: "D14",
    badge: "",
    ax: 435,
    ay: 2614,
    area: D14,
    color: pink,
    type: "dome",
  },
  {
    x: 447,
    y: 2546,
    title: "D15",
    badge: "",
    ax: 455,
    ay: 2564,
    area: D15,
    color: pink,
    type: "dome",
  },
  {
    x: 497,
    y: 2644,
    title: "D16",
    badge: "",
    ax: 523,
    ay: 2653,
    area: D16,
    color: pink,
    type: "dome",
  },
  {
    x: 529,
    y: 2627,
    title: "D17",
    badge: "",
    ax: 560,
    ay: 2633,
    area: D17,
    color: pink,
    type: "dome",
  },
  {
    x: 530,
    y: 2597,
    title: "D18",
    badge: "",
    ax: 565,
    ay: 2589,
    area: D18,
    color: pink,
    type: "dome",
  },
  {
    x: 774,
    y: 2589,
    title: "D19",
    badge: "",
    ax: 739,
    ay: 2579,
    area: D19,
    color: pink,
    type: "dome",
  },
  {
    x: 761,
    y: 2632,
    title: "D20",
    badge: "",
    ax: 727,
    ay: 2610,
    area: D20,
    color: pink,
    type: "dome",
  },
  {
    x: 716,
    y: 2652,
    title: "D21",
    badge: "",
    ax: 687,
    ay: 2626,
    area: D21,
    color: pink,
    type: "dome",
  },
  {
    x: 643,
    y: 2711,
    title: "D22",
    badge: "",
    ax: 619,
    ay: 2675,
    area: D22,
    color: pink,
    type: "dome",
  },
  {
    x: 616,
    y: 2736,
    title: "D23",
    badge: "",
    ax: 592,
    ay: 2699,
    area: D23,
    color: pink,
    type: "dome",
  },
  {
    x: 581,
    y: 2759,
    title: "D24",
    badge: "",
    ax: 565,
    ay: 2719,
    area: D24,
    color: pink,
    type: "dome",
  },
  {
    x: 362,
    y: 2783,
    title: "D25",
    badge: "",
    ax: 383,
    ay: 2748,
    area: D25,
    color: pink,
    type: "dome",
  },
  {
    x: 414,
    y: 2788,
    title: "D26",
    badge: "",
    ax: 419,
    ay: 2752,
    area: D26,
    color: pink,
    type: "dome",
  },
  {
    x: 461,
    y: 2782,
    title: "D27",
    badge: "",
    ax: 458,
    ay: 2748,
    area: D27,
    color: pink,
    type: "dome",
  },
  {
    x: 794,
    y: 2681,
    title: "D28",
    badge: "",
    ax: 796,
    ay: 2651,
    area: D28,
    color: pink,
    type: "dome",
  },
  {
    x: 916,
    y: 2643,
    title: "D29",
    badge: "",
    ax: 881,
    ay: 2634,
    area: D29,
    color: pink,
    type: "dome",
  },
  {
    x: 912,
    y: 2676,
    title: "D30",
    badge: "",
    ax: 877,
    ay: 2666,
    area: D30,
    color: pink,
    type: "dome",
  },
  {
    x: 897,
    y: 2777,
    title: "D31",
    badge: "",
    ax: 868,
    ay: 2742,
    area: D31,
    color: pink,
    type: "dome",
  },
  {
    x: 861,
    y: 2802,
    title: "D32",
    badge: "",
    ax: 840,
    ay: 2763,
    area: D32,
    color: pink,
    type: "dome",
  },
  {
    x: 814,
    y: 2825,
    title: "D33",
    badge: "",
    ax: 816,
    ay: 2792,
    area: D33,
    color: pink,
    type: "dome",
  },
  {
    x: 752,
    y: 2729,
    title: "D34",
    badge: "",
    ax: 720,
    ay: 2721,
    area: D34,
    color: pink,
    type: "dome",
  },
  {
    x: 741,
    y: 2772,
    title: "D35",
    badge: "",
    ax: 704,
    ay: 2756,
    area: D35,
    color: pink,
    type: "dome",
  },
  {
    x: 699,
    y: 2814,
    title: "D36",
    badge: "",
    ax: 674,
    ay: 2778,
    area: D36,
    color: pink,
    type: "dome",
  },
  {
    x: 648,
    y: 2839,
    title: "D37",
    badge: "",
    ax: 629,
    ay: 2805,
    area: D37,
    color: pink,
    type: "dome",
  },
  {
    x: 616,
    y: 2861,
    title: "D38",
    badge: "",
    ax: 598,
    ay: 2822,
    area: D38,
    color: pink,
    type: "dome",
  },
  {
    x: 580,
    y: 2881,
    title: "D39",
    badge: "",
    ax: 568,
    ay: 2840,
    area: D39,
    color: pink,
    type: "dome",
  },
  {
    x: 369,
    y: 2859,
    title: "D40",
    badge: "",
    ax: 387,
    ay: 2878,
    area: D40,
    color: pink,
    type: "dome",
  },
  {
    x: 403,
    y: 2841,
    title: "D41",
    badge: "",
    ax: 417,
    ay: 2860,
    area: D41,
    color: pink,
    type: "dome",
  },
  {
    x: 436,
    y: 2828,
    title: "D42",
    badge: "",
    ax: 449,
    ay: 2843,
    area: D42,
    color: pink,
    type: "dome",
  },
  {
    x: 371,
    y: 3010,
    title: "D43",
    badge: "",
    ax: 365,
    ay: 2971,
    area: D43,
    color: pink,
    type: "dome",
  },
  {
    x: 416,
    y: 3000,
    title: "D44",
    badge: "",
    ax: 401,
    ay: 2962,
    area: D44,
    color: pink,
    type: "dome",
  },
  {
    x: 449,
    y: 2982,
    title: "D45",
    badge: "",
    ax: 432,
    ay: 2945,
    area: D45,
    color: pink,
    type: "dome",
  },
  {
    x: 484,
    y: 2970,
    title: "D46",
    badge: "",
    ax: 462,
    ay: 2925,
    area: D46,
    color: pink,
    type: "dome",
  },
  // {
  //   x: 25-3,
  //   y: 1822,
  //   title: "D47",
  //   badge: "",
  //   ax: 230,
  //   ay: 1797,
  //   area: D47,
  //   color: pink,
  //   type: "dome",
  // },
  {
    x: 542,
    y: 2939,
    title: "D48",
    badge: "",
    ax: 525,
    ay: 2901,
    area: D48,
    color: pink,
    type: "dome",
  },
  {
    x: 521,
    y: 2958,
    title: "D49",
    badge: "",
    ax: 497,
    ay: 2916,
    area: D49,
    color: pink,
    type: "dome",
  },
  {
    x: 502,
    y: 2695,
    title: "D50",
    badge: "",
    ax: 462,
    ay: 2690,
    area: D50,
    color: pink,
    type: "dome",
  },
  {
    x: 489,
    y: 2534,
    title: "D51",
    badge: "",
    ax: 494,
    ay: 2552,
    area: D51,
    color: pink,
    type: "dome",
  },
  {
    x: 539,
    y: 2523,
    title: "D52",
    badge: "",
    ax: 547,
    ay: 2543,
    area: D52,
    color: pink,
    type: "dome",
  },
  {
    x: 771,
    y: 2554,
    title: "D53",
    badge: "",
    ax: 740,
    ay: 2545,
    area: D53,
    color: pink,
    type: "dome",
  },
  {
    x: 908,
    y: 2710,
    title: "D54",
    badge: "",
    ax: 872,
    ay: 2698,
    area: D54,
    color: pink,
    type: "dome",
  },
  {
    x: 770,
    y: 2814,
    title: "D55",
    badge: "",
    ax: 778,
    ay: 2774,
    area: D55,
    color: pink,
    type: "dome",
  },
  {
    x: 754,
    y: 2693,
    title: "D56",
    badge: "",
    ax: 722,
    ay: 2687,
    area: D56,
    color: pink,
    type: "dome",
  },
  {
    x: 790,
    y: 2742,
    title: "D58",
    badge: "",
    ax: 795,
    ay: 2710,
    area: D58,
    color: pink,
    type: "dome",
  },
];

const phase4AreaSetPrevious = [
  // {
  //   x: 586,
  //   y: 2476,
  //   title: "P4-01",
  //   badge: "",
  //   ax: 546,
  //   ay: 2405,
  //   area: P4_1,
  //   color: pink,
  // },
  // {
  //   x: 500,
  //   y: 2397,
  //   title: "P4-02",
  //   badge: "",
  //   ax: 469,
  //   ay: 2333,
  //   area: P4_2,
  //   color: pink,
  // },
  {
    x: 170,
    y: 1815,
    title: "P4-03",
    badge: "",
    ax: 129,
    ay: 1748,
    area: P4_3,
    color: pink,
  },
  {
    x: 326,
    y: 1764,
    title: "P4-04",
    badge: "",
    ax: 257,
    ay: 1708,
    area: P4_4,
    color: pink,
  },
  {
    x: 462,
    y: 1764,
    title: "P4-05",
    badge: "",
    ax: 407,
    ay: 1708,
    area: P4_5,
    color: pink,
  },
  // {
  //   x: 504,
  //   y: 1490,
  //   title: "",
  //   badge: "",
  //   ax: 550,
  //   ay: 1393,
  //   area: P4_6,
  //   color: pink,
  // },
  // {
  //   x: 627,
  //   y: 1481,
  //   title: "P4-07",
  //   badge: "",
  //   ax: 616,
  //   ay: 1416,
  //   area: P4_7,
  //   color: pink,
  // },
  // {
  //   x: 690,
  //   y: 1514,
  //   title: "P4-08",
  //   badge: "",
  //   ax: 682,
  //   ay: 1437,
  //   area: P4_8,
  //   color: pink,
  // },
  {
    x: 856,
    y: 1740,
    title: "P4-09",
    badge: "",
    ax: 813,
    ay: 1661,
    area: P4_9,
    color: pink,
  },
  // {
  //   x: 840,
  //   y: 1476,
  //   title: "P4-10",
  //   badge: "",
  //   ax: 803,
  //   ay: 1404,
  //   area: P4_10,
  //   color: pink,
  // },
  {
    x: 992,
    y: 1688,
    title: "P4-11",
    badge: "",
    ax: 934,
    ay: 1601,
    area: P4_11,
    color: pink,
  },
  {
    x: 1110,
    y: 1643,
    title: "P4-12",
    badge: "",
    ax: 1052,
    ay: 1545,
    area: P4_12,
    color: pink,
  },
  {
    x: 1056,
    y: 1496,
    title: "P4-13",
    badge: "",
    ax: 1012,
    ay: 1433,
    area: P4_13,
    color: pink,
  },
  {
    x: 975,
    y: 1522,
    title: "P4-14",
    badge: "",
    ax: 937,
    ay: 1443,
    area: P4_14,
    color: pink,
  },
  {
    x: 900,
    y: 1563,
    title: "P4-15",
    badge: "",
    ax: 863,
    ay: 1482,
    area: P4_15,
    color: pink,
  },
  {
    x: 832,
    y: 1613,
    title: "P4-16",
    badge: "",
    ax: 796,
    ay: 1523,
    area: P4_16,
    color: pink,
  },
  {
    x: 743,
    y: 1636,
    title: "P4-17",
    badge: "",
    ax: 736,
    ay: 1559,
    area: P4_17,
    color: pink,
  },
  {
    x: 658,
    y: 1606,
    title: "P4-18",
    badge: "",
    ax: 632,
    ay: 1511,
    area: P4_18,
    color: pink,
  },
  {
    x: 578,
    y: 1558,
    title: "P4-19",
    badge: "",
    ax: 565,
    ay: 1430,
    area: P4_19,
    color: pink,
  },
  {
    x: 516,
    y: 1506,
    title: "P4-20",
    badge: "",
    ax: 497,
    ay: 1430,
    area: P4_20,
    color: pink,
  },
  {
    x: 420,
    y: 1524,
    title: "P4-21",
    badge: "",
    ax: 401,
    ay: 1472,
    area: P4_21,
    color: pink,
  },
  {
    x: 449,
    y: 1647,
    title: "P4-22",
    badge: "",
    ax: 391,
    ay: 1587,
    area: P4_22,
    color: pink,
  },
  {
    x: 275,
    y: 1676,
    title: "P4-23",
    badge: "",
    ax: 163,
    ay: 1600,
    area: P4_23,
    color: pink,
  },
  {
    x: 219,
    y: 1588,
    title: "P4-24",
    badge: "",
    ax: 163,
    ay: 1535,
    area: P4_24,
    color: pink,
  },
  {
    x: 241,
    y: 1481,
    title: "P4-25",
    badge: "",
    ax: 200,
    ay: 1437,
    area: P4_25,
    color: pink,
  },
  {
    x: 257,
    y: 1384,
    title: "P4-26",
    badge: "",
    ax: 216,
    ay: 1362,
    area: P4_26,
    color: pink,
  },
  {
    x: 262,
    y: 1309,
    title: "P4-27",
    badge: "",
    ax: 228,
    ay: 1288,
    area: P4_27,
    color: pink,
  },
  {
    x: 277,
    y: 1240,
    title: "P4-28",
    badge: "",
    ax: 240,
    ay: 1205,
    area: P4_28,
    color: pink,
  },
  {
    x: 295,
    y: 1164,
    title: "P4-29",
    badge: "",
    ax: 254,
    ay: 1131,
    area: P4_29,
    color: pink,
  },
  {
    x: 300,
    y: 1091,
    title: "P4-30",
    badge: "",
    ax: 266,
    ay: 1058,
    area: P4_30,
    color: pink,
  },
  {
    x: 337,
    y: 1022,
    title: "P4-31",
    badge: "",
    ax: 278,
    ay: 853,
    area: P4_31,
    color: pink,
  },
  {
    x: 306,
    y: 932,
    title: "P4-32",
    badge: "",
    ax: 278,
    ay: 853,
    area: P4_32,
    color: pink,
  },
  {
    x: 481,
    y: 1107,
    title: "P4-33",
    badge: "",
    ax: 442,
    ay: 1068,
    area: P4_33,
    color: pink,
  },
  {
    x: 464,
    y: 1175,
    title: "P4-34",
    badge: "",
    ax: 424,
    ay: 1142,
    area: P4_34,
    color: pink,
  },
  {
    x: 460,
    y: 1251,
    title: "P4-35",
    badge: "",
    ax: 406,
    ay: 1219,
    area: P4_35,
    color: pink,
  },
  {
    x: 447,
    y: 1322,
    title: "P4-36",
    badge: "",
    ax: 396,
    ay: 1296,
    area: P4_36,
    color: pink,
  },
  {
    x: 429,
    y: 1396,
    title: "P4-37",
    badge: "",
    ax: 390,
    ay: 1371,
    area: P4_37,
    color: pink,
  },
  {
    x: 558,
    y: 1334,
    title: "P4-38",
    badge: "",
    ax: 543,
    ay: 1247,
    area: P4_38,
    color: pink,
  },
  {
    x: 654,
    y: 1303,
    title: "P4-39",
    badge: "",
    ax: 627,
    ay: 1256,
    area: P4_39,
    color: pink,
  },
  {
    x: 691,
    y: 1458,
    title: "P4-40",
    badge: "",
    ax: 674,
    ay: 1358,
    area: P4_40,
    color: pink,
  },
  // {
  //   x: 702,
  //   y: 1172,
  //   title: "P4-41",
  //   badge: "",
  //   ax: 685,
  //   ay: 1067,
  //   area: P4_41,
  //   color: pink,
  // },
  // {
  //   x: 749,
  //   y: 1115,
  //   title: "P4-42",
  //   badge: "",
  //   ax: 729,
  //   ay: 1061,
  //   area: P4_42,
  //   color: pink,
  // },
  {
    x: 935,
    y: 1382,
    title: "P4-43",
    badge: "",
    ax: 836,
    ay: 1332,
    area: P4_43,
    color: pink,
  },
  {
    x: 880,
    y: 1326,
    title: "P4-44",
    badge: "",
    ax: 807,
    ay: 1269,
    area: P4_44,
    color: pink,
  },
  {
    x: 836,
    y: 1271,
    title: "P4-45",
    badge: "",
    ax: 774,
    ay: 1219,
    area: P4_45,
    color: pink,
  },
  {
    x: 840,
    y: 1179,
    title: "P4-46",
    badge: "",
    ax: 811,
    ay: 1064,
    area: P4_46,
    color: pink,
  },
  {
    x: 750,
    y: 1120,
    title: "P4-47",
    badge: "",
    ax: 725,
    ay: 1058,
    area: P4_47,
    color: pink,
  },
  {
    x: 663,
    y: 1155,
    title: "P4-48",
    badge: "",
    ax: 652,
    ay: 1071,
    area: P4_48,
    color: pink,
  },
  {
    x: 581,
    y: 1200,
    title: "P4-49",
    badge: "",
    ax: 575,
    ay: 1089,
    area: P4_49,
    color: pink,
  },
  // {
  //   x: 867,
  //   y: 1637,
  //   title: "P4-50",
  //   badge: "",
  //   ax: 838,
  //   ay: 1545,
  //   area: P4_50,
  //   color: pink,
  // },
  {
    x: 1030,
    y: 1887,
    title: "P4-51",
    badge: "",
    ax: 1009,
    ay: 1801,
    area: P4_51,
    color: pink,
  },
  {
    x: 1093,
    y: 1830,
    title: "P4-52",
    badge: "",
    ax: 1083,
    ay: 1777,
    area: P4_52,
    color: pink,
  },
  {
    x: 1187,
    y: 1835,
    title: "P4-53",
    badge: "",
    ax: 1154,
    ay: 1746,
    area: P4_53,
    color: pink,
  },
  {
    x: 1240,
    y: 1754,
    title: "P4-54",
    badge: "",
    ax: 1196,
    ay: 1698,
    area: P4_54,
    color: pink,
  },
  {
    x: 1271,
    y: 1663,
    title: "P4-55",
    badge: "",
    ax: 1225,
    ay: 1571,
    area: P4_55,
    color: pink,
  },
  {
    x: 1246,
    y: 1561,
    title: "P4-56",
    badge: "",
    ax: 1200,
    ay: 1491,
    area: P4_56,
    color: pink,
  },
  {
    x: 1224,
    y: 1491,
    title: "P4-57",
    badge: "",
    ax: 1165,
    ay: 1409,
    area: P4_57,
    color: pink,
  },
  // {
  //   x: 1266,
  //   y: 1432,
  //   title: "P4-58",
  //   badge: "",
  //   ax: 1217,
  //   ay: 1386,
  //   area: P4_58,
  //   color: pink,
  // },
  {
    x: 1143,
    y: 1352,
    title: "P4-59",
    badge: "",
    ax: 1097,
    ay: 1233,
    area: P4_59,
    color: pink,
  },
  {
    x: 1120,
    y: 1210,
    title: "P4-60",
    badge: "",
    ax: 1039,
    ay: 1028,
    area: P4_60,
    color: pink,
  },
  {
    x: 1116,
    y: 1089,
    title: "P4-61",
    badge: "",
    ax: 943,
    ay: 954,
    area: P4_61,
    color: pink,
  },
  {
    x: 1075,
    y: 1022,
    title: "P4-62",
    badge: "",
    ax: 927,
    ay: 897,
    area: P4_62,
    color: pink,
  },
  {
    x: 958,
    y: 1002,
    title: "P4-63",
    badge: "",
    ax: 892,
    ay: 947,
    area: P4_63,
    color: pink,
  },
  {
    x: 876,
    y: 964,
    title: "P4-64",
    badge: "",
    ax: 840,
    ay: 836,
    area: P4_64,
    color: pink,
  },
  {
    x: 788,
    y: 871,
    title: "P4-65",
    badge: "",
    ax: 761,
    ay: 803,
    area: P4_65,
    color: pink,
  },
  {
    x: 699,
    y: 915,
    title: "P4-66",
    badge: "",
    ax: 683,
    ay: 803,
    area: P4_66,
    color: pink,
  },
  {
    x: 617,
    y: 956,
    title: "P4-67",
    badge: "",
    ax: 606,
    ay: 830,
    area: P4_67,
    color: pink,
  },
  {
    x: 538,
    y: 994,
    title: "P4-68",
    badge: "",
    ax: 533,
    ay: 846,
    area: P4_68,
    color: pink,
  },
  {
    x: 460,
    y: 937,
    title: "P4-69",
    badge: "",
    ax: 427,
    ay: 876,
    area: P4_69,
    color: pink,
  },
  {
    x: 427,
    y: 834,
    title: "P4-70",
    badge: "",
    ax: 377,
    ay: 779,
    area: P4_70,
    color: pink,
  },
  {
    x: 411,
    y: 707,
    title: "P4-71",
    badge: "",
    ax: 374,
    ay: 672,
    area: P4_71,
    color: pink,
  },
  {
    x: 551,
    y: 681,
    title: "P4-72",
    badge: "",
    ax: 510,
    ay: 630,
    area: P4_72,
    color: pink,
  },
  {
    x: 642,
    y: 713,
    title: "P4-73",
    badge: "",
    ax: 643,
    ay: 593,
    area: P4_73,
    color: pink,
  },
  {
    x: 719,
    y: 649,
    title: "P4-74",
    badge: "",
    ax: 722,
    ay: 576,
    area: P4_74,
    color: pink,
  },
  {
    x: 800,
    y: 709,
    title: "P4-75",
    badge: "",
    ax: 800,
    ay: 619,
    area: P4_75,
    color: pink,
  },
  {
    x: 891,
    y: 747,
    title: "P4-76",
    badge: "",
    ax: 890,
    ay: 656,
    area: P4_76,
    color: pink,
  },
  {
    x: 981,
    y: 762,
    title: "P4-77",
    badge: "",
    ax: 964,
    ay: 656,
    area: P4_77,
    color: pink,
  },
  {
    x: 1098,
    y: 773,
    title: "P4-78",
    badge: "",
    ax: 1021,
    ay: 626,
    area: P4_78,
    color: pink,
  },
  {
    x: 814,
    y: 551,
    title: "P4-79",
    badge: "",
    ax: 771,
    ay: 443,
    area: P4_79,
    color: pink,
  },
  {
    x: 913,
    y: 524,
    title: "P4-80",
    badge: "",
    ax: 903,
    ay: 435,
    area: P4_80,
    color: pink,
  },
  {
    x: 1032,
    y: 506,
    title: "P4-81",
    badge: "",
    ax: 1005,
    ay: 444,
    area: P4_81,
    color: pink,
  },
  {
    x: 1095,
    y: 395,
    title: "P4-82",
    badge: "",
    ax: 1032,
    ay: 290,
    area: P4_82,
    color: pink,
  },
  {
    x: 955,
    y: 334,
    title: "P4-83",
    badge: "",
    ax: 869,
    ay: 219,
    area: P4_83,
    color: pink,
  },
  {
    x: 851,
    y: 365,
    title: "P4-84",
    badge: "",
    ax: 810,
    ay: 261,
    area: P4_84,
    color: pink,
  },
  // {
  //   x: 949,
  //   y: 381,
  //   title: "P4-85",
  //   badge: "",
  //   ax: 934,
  //   ay: 301,
  //   area: P4_85,
  //   color: pink,
  // },
  // {
  //   x: 1061,
  //   y: 364,
  //   title: "P4-86",
  //   badge: "",
  //   ax: 1037,
  //   ay: 311,
  //   area: P4_86,
  //   color: pink,
  // },
  {
    x: 553,
    y: 564,
    title: "P4-87",
    badge: "",
    ax: 505,
    ay: 532,
    area: P4_87,
    color: pink,
  },
  // {
  //   x: 980,
  //   y: 211,
  //   title: "P4-88",
  //   badge: "",
  //   ax: 903,
  //   ay: 107,
  //   area: P4_88,
  //   color: pink,
  // },
  // {
  //   x: 887,
  //   y: 250,
  //   title: "P4-89",
  //   badge: "",
  //   ax: 850,
  //   ay: 145,
  //   area: P4_89,
  //   color: pink,
  // },
  // {
  //   x: 132,
  //   y: 1744,
  //   title: "P4-90",
  //   badge: "",
  //   ax: 110,
  //   ay: 1595,
  //   area: P4_90,
  //   color: pink,
  // },
  // {
  //   x: 192,
  //   y: 1824,
  //   title: "P4-91",
  //   badge: "",
  //   ax: 180,
  //   ay: 1664,
  //   area: P4_91,
  //   color: pink,
  // },
  // {
  //   x: 263,
  //   y: 1777,
  //   title: "P4-92",
  //   badge: "",
  //   ax: 264,
  //   ay: 1652,
  //   area: P4_92,
  //   color: pink,
  // },
  // {
  //   x: 332,
  //   y: 1720,
  //   title: "P4-93",
  //   badge: "",
  //   ax: 322,
  //   ay: 1644,
  //   area: P4_93,
  //   color: pink,
  // },
  // {
  //   x: 311,
  //   y: 2035,
  //   title: "P4-94",
  //   badge: "",
  //   ax: 250,
  //   ay: 1902,
  //   area: P4_94,
  //   color: pink,
  // },
  // {
  //   x: 349,
  //   y: 2234,
  //   title: "P4-95",
  //   badge: "",
  //   ax: 281,
  //   ay: 2150,
  //   area: P4_95,
  //   color: pink,
  // },
  // {
  //   x: 280,
  //   y: 1745,
  //   title: "P4-96",
  //   badge: "",
  //   ax: 266,
  //   ay: 1664,
  //   area: P4_96,
  //   color: pink,
  // },
  // {
  //   x: 369,
  //   y: 1688,
  //   title: "P4-97",
  //   badge: "",
  //   ax: 354,
  //   ay: 1640,
  //   area: P4_97,
  //   color: pink,
  // },
  // {
  //   x: 379,
  //   y: 1756,
  //   title: "P4-98",
  //   badge: "",
  //   ax: 353,
  //   ay: 1713,
  //   area: P4_98,
  //   color: pink,
  // },
  // {
  //   x: 379,
  //   y: 1832,
  //   title: "P4-99",
  //   badge: "",
  //   ax: 350,
  //   ay: 1787,
  //   area: P4_99,
  //   color: pink,
  // },
  // {
  //   x: 398,
  //   y: 2038,
  //   title: "P4-100",
  //   badge: "",
  //   ax: 336,
  //   ay: 1904,
  //   area: P4_100,
  //   color: pink,
  // },
  // {
  //   x: 436,
  //   y: 2239,
  //   title: "P4-101",
  //   badge: "",
  //   ax: 367,
  //   ay: 2154,
  //   area: P4_101,
  //   color: pink,
  // },
];

export {
  areaSet,
  phase2AreaSet,
  phase3AreaSet,
  phase4AreaSet,
  phase4AreaSetPrevious,
};
